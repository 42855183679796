import membershipDto from '@/store/dto/membership/membershipDto';
import StripeSubscription from '@/store/models/StripeSubscription';

import accountService from '@/services/account/accountService';
import shopifyBillingService from '@/services/shopify/shopifyBillingService';
import StripeApiRepository from '@/services/stripe/StripeApiRepository';

/**
 * @typedef {Object} MembershipState
 * @property {Object} membershipState
 * @property {StripeSubscription|null} stripeSubscriptionState
 */

/**
 * @returns {MembershipState}
 */
const state = () => ({
    membershipState: JSON.parse(localStorage.getItem('accountMembership')) || membershipDto,
    stripeSubscriptionState: JSON.parse(localStorage.getItem('stripeSubscription'))
        ? StripeSubscription.fromObject(JSON.parse(localStorage.getItem('stripeSubscription')))
        : null,
});

const getters = {
    membership(state) {
        return state.membershipState;
    },

    /**
     * @param {MembershipState} state
     * @returns {string}
     */
    membershipId(state) {
        return state.membershipState.uuid;
    },

    /**
     * @param {MembershipState} state
     * @returns {StripeSubscription|null}
     */
    stripeSubscription(state) {
        return state.stripeSubscriptionState;
    },

    ifSubscribed(state) {
        return state.membershipState.membership_plan !== 'FREE';
    },

    /**
     * @param {MembershipState} state
     * @param {Object} getters
     * @returns {string|null}
     */
    findProductId(state, getters) {
        if (!getters.ifSubscribed) return null;

        return state.stripeSubscriptionState.productId;
    },

    membershipPlanName: (state) => {
        return state.membershipState.membership_plan;
    },

    subscriptionEndDate: (state) => {
        return state.membershipState.subscription_end_date;
    },

    /**
     * @param {MembershipState} state
     * @returns {number|null}
     */
    subscriptionTrialEndDate: (state) => {
        return state.stripeSubscriptionState.trialEnd;
    },

    shopifySubscriptionTrialEndDate: (state) => {
        return state.membershipState.trial_end_date;
    },

    consumedConversations: (state, getters) => {
        return getters.totalConversations - state.membershipState.total_conversations || 0;
    },

    totalConversations: (state) => {
        return (
            state.membershipState.membership_conversations +
                state.membershipState.pack_conversations +
                state.membershipState.prorated_conversations || 0
        );
    },

    AreConversationsConsumed: (state, getters) => {
        return getters.consumedConversations === getters.totalConversations;
    },

    /**
     * @param {MembershipState} state
     * @param {Object} getters
     * @returns {boolean}
     */
    isInTrialPeriod: (state, getters) => {
        if (!getters.ifSubscribed) return null;
        if (!state.stripeSubscriptionState) return null;

        return state.stripeSubscriptionState.isTrialing();
    },

    shopifyIsInTrialPeriod: (state) => {
        const currentTimestamp = Math.floor(Date.now() / 1000);
        const trialEndTimestamp = state.membershipState.trial_end_date;
        return currentTimestamp < trialEndTimestamp;
    },

    /**
     * @param {MembershipState} state
     * @returns {boolean}
     */
    cancelAtPeriodEnd: (state) => {
        return state.stripeSubscriptionState.cancelAtPeriodEnd;
    },

    shopifyCancelAtPeriodEnd: (state) => {
        return state.membershipState.cancel_at_period_end;
    },

    /**
     * @param {MembershipState} state
     * @returns {number|null}
     */
    cancelAt: (state) => {
        return state.stripeSubscriptionState.cancelAt;
    },

    shopifyCancelAt: (state) => {
        return state.membershipState.subscription_cancel_at;
    },

    membershipPlan: (state) => {
        return state.membershipState.membership_plan;
    },
};

const mutations = {
    SET_MEMBERSHIP_STATE(state, newMembershipState) {
        state.membershipState = JSON.parse(JSON.stringify(newMembershipState));
        localStorage.setItem('accountMembership', JSON.stringify(newMembershipState));
    },

    CLEAR_MEMBERSHIP_STATE(state) {
        state.membershipState = membershipDto;
    },

    /**
     * @param {MembershipState} state
     * @param {StripeSubscription} newStripeSubscriptionState
     */
    SET_STRIPE_SUBSCRIPTION_STATE(state, newStripeSubscriptionState) {
        state.stripeSubscriptionState = StripeSubscription.fromObject(newStripeSubscriptionState.toObject());
        localStorage.setItem('stripeSubscription', JSON.stringify(newStripeSubscriptionState.toObject()));
    },

    /**
     * @param {MembershipState} state
     */
    CLEAR_STRIPE_SUBSCRIPTION_STATE(state) {
        state.stripeSubscriptionState = null;
    },
};

const actions = {
    async fetchStripeSubscription({ commit, getters }, membershipId) {
        if (!getters.ifSubscribed) return;

        try {
            const subscriptionResponseData = await StripeApiRepository.getSubscription(membershipId);

            const stripeSubscription = new StripeSubscription.Builder()
                .withId(subscriptionResponseData.id)
                .withProductId(subscriptionResponseData.productId)
                .withProductName(subscriptionResponseData.productName)
                .withTrialEnd(subscriptionResponseData.trialEnd)
                .withStatus(subscriptionResponseData.status)
                .withCancelAtPeriodEnd(subscriptionResponseData.cancelAtPeriodEnd)
                .withCancelAt(subscriptionResponseData.cancelAt)
                .build();

            commit('SET_STRIPE_SUBSCRIPTION_STATE', stripeSubscription);
        } catch (error) {
            console.error('Error fetching Stripe subscription:', error);
        }
    },

    clearMembershipState({ commit }) {
        localStorage.removeItem('accountMembership');
        commit('CLEAR_MEMBERSHIP_STATE');
    },

    clearStripeSubscriptionState({ commit }) {
        localStorage.removeItem('stripeSubscription');
        commit('CLEAR_STRIPE_SUBSCRIPTION_STATE');
    },

    async getAccountMembership({ commit }) {
        try {
            const getAccountMembershipResponse = await accountService.fetchAccountMembership();

            commit('SET_MEMBERSHIP_STATE', getAccountMembershipResponse.data);

            return getAccountMembershipResponse;
        } catch (e) {
            console.error('Error in getting account membership: ', e);
        }
    },

    async cancelShopifySubscription(_, membershipId) {
        try {
            const response = await shopifyBillingService.cancelShopifySubscription(membershipId);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async getAccountMembershipByExternalBillingId({ commit }, externalBillingId) {
        try {
            const response = await accountService.getAccountMembershipByExternalBillingId(externalBillingId);

            commit('SET_MEMBERSHIP_STATE', response.data);

            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async subscribeToMembershipUpdates({ state, dispatch }) {
        const topic = `/account/${state.membershipState.account_id}/membership`;
        try {
            await dispatch(
                'websocket/subscribe',
                {
                    topic,
                    callback: (data) => {
                        dispatch('handleMembershipUpdate', data);
                    },
                },
                { root: true },
            );
        } catch (error) {
            console.error(`Failed to subscribe to ${topic}:`, error);
        }
    },

    unsubscribeFromMembershipUpdates({ state, dispatch }) {
        const topic = `/account/${state.membershipState.account_id}/membership`;
        dispatch('websocket/unsubscribe', { topic }, { root: true });
    },

    handleMembershipUpdate({ commit }, event) {
        if (!event || !event.data) return;

        try {
            const parsedData = JSON.parse(event.data);
            switch (event.name) {
                case 'MembershipUpdated':
                case 'MembershipDowngraded':
                    commit('SET_MEMBERSHIP_STATE', parsedData);
                    break;
                // Add more cases if needed
                default:
                    console.warn(`Unhandled membership event: ${event.name}`);
            }
        } catch (error) {
            console.error('Error parsing membership update data:', error);
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
