<template>
    <div class="phone-checker">
        <h2 class="step-title md-subheading">Enter Phone Number</h2>
        <div class="phone-input">
            <md-field class="country-code">
                <label for="country-code">Country</label>
                <md-select
                    v-model="selectedCountryCode"
                    name="country-code"
                    id="country-code"
                    @md-selected="updatePhoneNumber"
                >
                    <md-option v-for="country in countries" :key="country.code" :value="country.code">
                        {{ country.name }} (+{{ country.dialCode }})
                    </md-option>
                </md-select>
            </md-field>
            <md-field :class="{ 'md-invalid': showError }">
                <label>Phone Number</label>
                <md-input v-model="phoneNumber" type="tel" @input="validateAndProceed"></md-input>
                <span class="md-error" v-if="showError">invalid phone number</span>
            </md-field>
        </div>
    </div>
</template>

<script>
import countryCodes from '@/assets/countryCodesData.json';

export default {
    name: 'CreateRoomPhoneChecker',
    props: {
        initialPhoneNumber: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            selectedCountryCode: 'ES',
            phoneNumber: '',
            isPhoneValid: false,
            countries: this.sortCountries(countryCodes),
            hasBeenEdited: false,
        };
    },
    computed: {
        selectedCountry() {
            return this.countries.find((country) => country.code === this.selectedCountryCode) || this.countries[0];
        },
        showError() {
            return !this.isPhoneValid && this.phoneNumber && this.hasBeenEdited;
        },
    },
    created() {
        this.initializePhoneNumber();
    },
    methods: {
        initializePhoneNumber() {
            if (this.initialPhoneNumber) {
                const countryCode = this.extractCountryCode(this.initialPhoneNumber);
                if (countryCode) {
                    this.selectedCountryCode = countryCode;
                }
                this.phoneNumber = this.extractNationalNumber(this.initialPhoneNumber);
                this.validateAndProceed();
            }
        },
        extractCountryCode(fullNumber) {
            for (const country of this.countries) {
                if (fullNumber.startsWith(`+${country.dialCode}`)) {
                    return country.code;
                }
            }
            return null;
        },
        extractNationalNumber(fullNumber) {
            const country = this.countries.find((c) => fullNumber.startsWith(`+${c.dialCode}`));
            if (country) {
                return fullNumber.slice(country.dialCode.length + 1); // +1 for the '+' sign
            }
            return fullNumber;
        },
        validateAndProceed() {
            // This regex allows for:
            // - Optional leading '+' sign
            // - 7 to 15 digits
            // - Optional spaces, dashes, or parentheses between digits
            const phoneRegex = /^(\+)?(?:[0-9] ?){6,14}[0-9]$/;

            const fullPhoneNumber = this.getFullPhoneNumber();
            this.isPhoneValid = phoneRegex.test(fullPhoneNumber);
            this.hasBeenEdited = true;

            // Remove all spaces from the phone number
            const cleanedPhoneNumber = fullPhoneNumber.replace(/\s/g, '');
            this.$emit('phone-changed', cleanedPhoneNumber);

            if (this.isPhoneValid) {
                this.$emit('step-completed', { phoneNumber: cleanedPhoneNumber, exists: false, customerName: '' });
            }
        },
        updatePhoneNumber() {
            this.phoneNumber = '';
            this.isPhoneValid = false;
            this.hasBeenEdited = false;
            this.$emit('phone-changed', '');
        },
        getFullPhoneNumber() {
            return `+${this.selectedCountry.dialCode}${this.phoneNumber}`;
        },
        sortCountries(countries) {
            const spain = countries.find((country) => country.code === 'ES');
            const otherCountries = countries.filter((country) => country.code !== 'ES');
            return [spain, ...otherCountries.sort((a, b) => a.name.localeCompare(b.name))];
        },
    },
};
</script>

<style scoped>
.phone-checker {
    display: flex;
    flex-direction: column;
}

.step-title {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 8px;
}

.phone-input {
    display: flex;
    gap: 8px;
}

.country-code {
    flex: 0 0 40%;
}

.md-field {
    margin-bottom: 0;
}
</style>
