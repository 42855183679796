<script>
import { mapActions } from 'vuex';
import CreateRoomCommunicationChannelSelector from './CreateRoomCommunicationChannelSelector.vue';
import CreateRoomPhoneChecker from './CreateRoomPhoneChecker.vue';
import CreateRoomContactForm from './CreateRoomContactForm.vue';

export default {
    name: 'CreateRoom',
    emits: ['room-created'],
    components: {
        CreateRoomCommunicationChannelSelector,
        CreateRoomPhoneChecker,
        CreateRoomContactForm,
    },
    props: {
        searchParam: {
            type: String,
            default: '',
        },
    },
    computed: {
        PhoneNumberFromSearchParam() {
            // Regular expression to match phone numbers with or without extension
            const phoneRegex = /^(\+\d{1,3})?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}([-.\s]?(ext|x)\d{1,5})?$/;

            if (this.searchParam && phoneRegex.test(this.searchParam.trim())) {
                return this.searchParam.trim();
            }
            return '';
        },
    },
    data() {
        return {
            selectedChannel: 'whatsapp',
            phoneNumber: '',
            contactName: '',
            phoneExists: false,
            isLoading: false,
            showPhoneChecker: true,
            showContactForm: false,
            isPhoneVerified: false,
            customerName: '',
            initialPhoneNumber: '',
        };
    },
    created() {
        this.initialPhoneNumber = this.PhoneNumberFromSearchParam;
        if (this.initialPhoneNumber) {
            this.handlePhoneChecked({ phoneNumber: this.initialPhoneNumber, exists: false, customerName: '' });
        }
    },
    methods: {
        ...mapActions('modalStore', ['closeModal']),
        handleChannelSelected(channel) {
            this.selectedChannel = channel;
            this.showPhoneChecker = channel === 'whatsapp';
            this.isPhoneVerified = false;
            this.showContactForm = false;
            this.customerName = '';
            if (channel === 'whatsapp' && this.initialPhoneNumber) {
                this.handlePhoneChecked({ phoneNumber: this.initialPhoneNumber, exists: false, customerName: '' });
            }
        },
        handlePhoneChecked(data) {
            this.phoneNumber = data.phoneNumber;
            this.phoneExists = false;
            this.isPhoneVerified = true;
            this.customerName = '';
            this.showContactForm = true;
        },
        handlePhoneChanged(newPhoneNumber) {
            this.phoneNumber = newPhoneNumber;
            this.isPhoneVerified = false;
            this.showContactForm = false;
            this.customerName = '';
        },
        handleContactFormCompleted(data) {
            this.contactName = data.contactName;
            this.submitForm();
        },
        submitForm() {
            if (!this.isPhoneVerified) {
                return;
            }
            const formData = {
                channel: this.selectedChannel,
                phoneNumber: this.phoneNumber,
                contactName: this.contactName,
            };
            // console.log('Form submitted', formData);
            this.$emit('modalEvent', { eventName: 'room-created', eventPayload: formData });
            this.closeModal();
        },
    },
};
</script>

<template>
    <div class="create-room">
        <div class="create-room__header">
            <md-button @click="closeModal()" class="md-icon-button">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <md-content class="create-room__content">
            <div class="create-room__form">
                <h1 class="create-room__title md-title">Create New Conversation</h1>
                <CreateRoomCommunicationChannelSelector @step-completed="handleChannelSelected" />
                <CreateRoomPhoneChecker
                    v-if="showPhoneChecker"
                    :initial-phone-number="initialPhoneNumber"
                    @step-completed="handlePhoneChecked"
                    @phone-changed="handlePhoneChanged"
                />
                <CreateRoomContactForm
                    v-if="showContactForm"
                    :initial-name="customerName"
                    @step-completed="handleContactFormCompleted"
                />
            </div>
        </md-content>
    </div>
</template>

<style lang="scss" scoped>
.create-room {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f5f5f5;
    width: 100%;
    max-width: 100%;

    @media (min-width: 768px) {
        width: 400px;
        max-width: 400px;
        margin: 0 auto;
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 12px 6px;
        padding-bottom: 0;
        background-color: #ffffff;
        // border-bottom: 1px solid #e0e0e0;
    }

    &__title {
        // font-size: 20px;
        // font-weight: 500;
        margin: 0;
        margin-bottom: 32px !important;
    }

    &__content {
        flex-grow: 1;
        padding: 16px;
        padding-top: 0;
        overflow-y: auto;
    }

    &__form {
        background-color: #ffffff;
        border-radius: 8px;
        padding: 16px;
        padding-top: 0;
        > * {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
</style>
