import BaseDto from '../../_common/BaseDto';

/**
 * @class PackPaymentLinkRequestBuilder
 * @description Builder class for PackPaymentLinkRequest
 */
class PackPaymentLinkRequestBuilder {
    /**
     * @param {string} toltioReferral - The Toltio referral code.
     * @returns {PackPaymentLinkRequestBuilder}
     */
    withToltioReferral(toltioReferral) {
        this.toltioReferral = toltioReferral;
        return this;
    }

    /**
     * @param {string} successUrl - The URL to redirect to on successful payment.
     * @returns {PackPaymentLinkRequestBuilder}
     */
    withSuccessUrl(successUrl) {
        this.successUrl = successUrl;
        return this;
    }

    /**
     * @param {string} cancelUrl - The URL to redirect to if the payment is canceled.
     * @returns {PackPaymentLinkRequestBuilder}
     */
    withCancelUrl(cancelUrl) {
        this.cancelUrl = cancelUrl;
        return this;
    }

    /**
     * @returns {PackPaymentLinkRequest} A new instance of PackPaymentLinkRequest.
     */
    build() {
        return new PackPaymentLinkRequest(this);
    }
}

/**
 * @class PackPaymentLinkRequest
 * @extends BaseDto
 * @description Represents a request for creating a pack payment link.
 */
class PackPaymentLinkRequest extends BaseDto {
    /**
     * @constructor
     * @param {PackPaymentLinkRequestBuilder} builder - The builder object containing the request data.
     */
    constructor(builder) {
        super();
        this.toltioReferral = builder.toltioReferral;
        this.successUrl = builder.successUrl;
        this.cancelUrl = builder.cancelUrl;
    }

    /**
     * @readonly
     * @static
     * @type {typeof PackPaymentLinkRequestBuilder}
     * @description Getter for the Builder class.
     */
    static get Builder() {
        return PackPaymentLinkRequestBuilder;
    }
}

export default PackPaymentLinkRequest;
