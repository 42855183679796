<script>
import LoginCard from '../../Cards/LoginCard.vue';
import { mapActions, mapGetters } from 'vuex';

import LoginForm from '@/components/Auth/Login/LoginForm.vue';
import LoginSocialButtonGoogle from '@/components/Auth/Login/LoginSocialButtonGoogle.vue';
import LoginSocialButtonFacebook from '@/components/Auth/Login/LoginSocialButtonFacebook.vue';
import AuthErrorPanel from '@/components/Auth/AuthErrorPanel.vue';

import Firebase from '@/Firebase.js';

export default {
    name: 'Login',
    data() {
        return {
            loading: false,
            error: '',
            queryParams: {},
        };
    },
    computed: {
        ...mapGetters('user', ['isShopifyBillingClient']),
    },
    components: {
        AuthErrorPanel,
        LoginSocialButtonFacebook,
        LoginSocialButtonGoogle,
        LoginForm,
        LoginCard,
    },
    methods: {
        ...mapActions('user', [
            'actionLogin',
            'actionGetUser',
            'actionGetAccount',
            'actionSignUpWithPopup',
            'actionSignUpWithFacebook',
        ]),
        ...mapActions('membership', ['getAccountMembership', 'subscribeToMembershipUpdates']),
        ...mapActions('agentSettings', ['updateAgentSetting']),

        logout() {
            this.$store
                .dispatch('user/logout')
                .then(() => {
                    if (this.$route.path !== '/') {
                        this.$router.push({ name: 'Login', query: { logout: 'true' } });
                    }
                })
                .catch((error) => {
                    this.error = error.message;
                });
        },

        handleIdentityToolkitError(error) {
            let identityToolkitErrorMessage = '';
            switch (error.message) {
                case 'INVALID_LOGIN_CREDENTIALS':
                case 'INVALID_PASSWORD':
                case 'EMAIL_NOT_FOUND':
                    identityToolkitErrorMessage = 'Invalid email or password.';
                    break;
            }
            return identityToolkitErrorMessage;
        },

        handleLixsaAuthServerError(error) {
            let lixsaAuthServerErrorMessage = '';

            switch (error.response.status) {
                case 500:
                    lixsaAuthServerErrorMessage = 'Server error. Please try again later';
                    break;
                default:
                    lixsaAuthServerErrorMessage = 'An error occurred. Please try again later';
                    break;
            }

            return lixsaAuthServerErrorMessage;
        },

        handleError(error) {
            // In case the error comes from Google Identity toolkit
            if (error.response.data.error) {
                this.error = this.handleIdentityToolkitError(error.response.data.error);
                return;
            }

            // In case the error comes from Lixsa Server
            this.error = this.handleLixsaAuthServerError(error);
        },

        async login(inputs) {
            this.error = '';
            const email = inputs[0].value;
            const password = inputs[1].value;

            this.loading = true;
            try {
                const idToken = await this.actionLogin({ email, password });

                if (!idToken) {
                    this.error = 'Authentication failed';
                    return;
                }

                await this.actionGetUser();
                await this.updateAgentSetting();
                const response = await this.actionGetAccount();
                if (response.status === 200 || response.status === 201) {
                    await this.getAccountMembership();

                    if (!this.isShopifyBillingClient) {
                        this.$store.dispatch(
                            'membership/fetchStripeSubscription',
                            this.$store.getters['membership/membershipId'],
                        );
                    }
                    this.subscribeToMembershipUpdates();
                    Firebase.requestPermission();

                    this.$router.push({ name: 'Dashboard', query: this.queryParams });
                }
            } catch (err) {
                this.handleError(err);
                this.logout();
            } finally {
                this.loading = false;
            }
        },

        handleQueryParams() {
            const query = this.$route.query;
            if (Object.keys(query).length > 0 && query.source === 'Shopify') {
                this.queryParams = JSON.parse(JSON.stringify(query));
            }
        },

        clearError() {
            this.error = '';
        },
    },
    created() {
        this.handleQueryParams();
    },
};
</script>

<template>
    <div class="md-layout text-center">
        <div class="custom-login-panel md-layout md-layout-item md-size-70 md-xsmall-size-100">
            <div class="md-layout-item md-size-100">
                <div class="md-display-1 login__title">
                    <strong> Sign in </strong>
                </div>
            </div>

            <div v-if="error !== ''" class="md-layout-item md-size-100">
                <AuthErrorPanel :error-message="error" @close-panel="clearError"></AuthErrorPanel>
            </div>

            <login-form :loading="loading" @perform-login="(args) => login(args)"></login-form>
        </div>
        <div class="md-layout-item md-size-100 md-xsmall-size-100">
            <p class="md-body-1">
                <router-link to="/register">CREATE AN ACCOUNT</router-link>
            </p>
        </div>

        <div class="md-layout-item md-size-70 md-xsmall-size-100">
            <div class="login__divider"></div>
        </div>

        <div class="md-layout-item md-size-70 md-xsmall-size-100">
            <div class="">
                <login-social-button-google
                    :query-params="queryParams"
                    @onError="
                        (socialButtonError) => {
                            handleError(socialButtonError);
                        }
                    "
                ></login-social-button-google>
                <login-social-button-facebook
                    :query-params="queryParams"
                    @onError="
                        (socialButtonError) => {
                            handleError(socialButtonError);
                        }
                    "
                ></login-social-button-facebook>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.custom-login-panel {
    padding-left: 0;
    padding-right: 0;
}

.login__title {
    text-align: start;
    margin-bottom: 2rem;
}

.login__divider {
    border-top: solid 1px #e7e7e7;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
}
</style>
