import BaseDto from '../../_common/BaseDto';

class LineItemDtoBuilder {
    withDescription(description) {
        this.description = description;
        return this;
    }

    withQuantity(quantity) {
        this.quantity = quantity;
        return this;
    }

    withPrice(price) {
        this.price = price;
        return this;
    }

    withCurrency(currency) {
        this.currency = currency;
        return this;
    }

    build() {
        return new LineItemDto(this);
    }
}

class LineItemDto extends BaseDto {
    constructor(builder) {
        super();
        this.description = builder.description;
        this.quantity = builder.quantity;
        this.price = builder.price;
        this.currency = builder.currency;
    }

    static get Builder() {
        return LineItemDtoBuilder;
    }
}

export default LineItemDto;
