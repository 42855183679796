<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';

export default {
    name: 'RoomWarningPanel',
    components: { SvgIcon },
    props: {
        warningText: {
            type: String,
            default: 'Your plan has run out of conversations. Lixsa will not respond to messages automatically.',
        },
        buttonText: {
            type: String,
            default: '',
        },
        buttonRoute: {
            type: String,
            default: '',
        },
        closable: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['close-panel'],
    methods: {
        goToPage(route) {
            if (this.$route.path !== route) {
                this.$router.push(route);
            }
        },
    },
};
</script>

<template>
    <div class="room-out-of-conversations-warning">
        <div v-if="closable" @click="$emit('close-panel')" class="room-out-of-conversations-warning__close">
            <svg-icon name="close_alt"></svg-icon>
        </div>
        <div class="room-out-of-conversations-warning__content">
            <div class="room-out-of-conversations-warning__content-message">
                <svg-icon name="warning" />
                <div class="md-caption">
                    <strong>
                        {{ warningText }}
                    </strong>
                </div>
            </div>
            <md-button v-if="buttonText && buttonRoute" @click="goToPage(buttonRoute)" class="md-primary md-sm">
                {{ buttonText }}
            </md-button>
        </div>
    </div>
</template>
