<!-- eslint-disable prettier/prettier -->
<template>
    <div
        v-show="showRoomsList"
        class="vac-rooms-container"
        :class="{
            'vac-rooms-container-full': isMobile,
            'vac-app-border-r': !isMobile,
        }"
    >
        <!-- <slot name="rooms-header" /> -->
        <room-list-tool-bar />

        <slot name="rooms-list-search">
            <rooms-search
                ref="roomsSearch"
                :rooms="rooms"
                :loading-rooms="loadingRooms"
                :text-messages="textMessages"
                :show-search="showSearch"
                :show-add-room="false"
                @search-room="searchRoom"
                @add-room="$emit('add-room')"
                @focus-search-input="focusSearchInput"
                @filter-activated="(agent, satisfaction) => $emit('filter-activated', agent, satisfaction)"
                :avisar="onParamValue"
            >
                <!-- @changeParamValue="searchParam" -->
                <template v-for="(idx, name) in $slots" #[name]="data">
                    <slot :name="name" v-bind="data" />
                </template>
            </rooms-search>
        </slot>

        <loader :show="loadingRooms" type="rooms">
            <template v-for="(idx, name) in $slots" #[name]="data">
                <slot :name="name" v-bind="data" />
            </template>
        </loader>

        <div v-if="!loadingRooms && !rooms.length && showAddRoom" class="vac-new-conversation-button">
            <md-button @click="$emit('add-room')" class="md-primary"> Create new conversation </md-button>
        </div>

        <div v-if="!loadingRooms && !rooms.length" class="vac-rooms-empty">
            <slot name="rooms-empty">
                <SvgIcon name="empty_conversations" />
                <span>{{ textMessages.ROOMS_EMPTY }}</span>
            </slot>
        </div>

        <div v-if="!loadingRooms" id="rooms-list" class="vac-room-list">
            <div
                v-for="fRoom in filteredRooms"
                :id="fRoom.roomId"
                :key="fRoom.roomId"
                class="vac-room-item"
                :class="{ 'vac-room-selected': selectedRoomId === fRoom.roomId }"
                @click="openRoom(fRoom)"
            >
                <room-content
                    :current-user-id="currentUserId"
                    :room="fRoom"
                    :text-formatting="textFormatting"
                    :link-options="linkOptions"
                    :text-messages="textMessages"
                    :room-actions="roomActions"
                    @room-action-handler="$emit('room-action-handler', $event)"
                >
                    <template v-for="(idx, name) in $slots" #[name]="data">
                        <slot :name="name" v-bind="data" />
                    </template>
                </room-content>
            </div>
            <transition name="vac-fade-message">
                <div v-if="rooms.length && !loadingRooms" id="infinite-loader-rooms">
                    <loader :show="showLoader" :infinite="true" type="infinite-rooms">
                        <template v-for="(idx, name) in $slots" #[name]="data">
                            <slot :name="name" v-bind="data" />
                        </template>
                    </loader>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Loader from '@/components/Chat/Loader/Loader.vue';

import RoomsSearch from './RoomsSearch/RoomsSearch.vue';
import RoomContent from './RoomContent/RoomContent.vue';
import RoomListToolBar from './RoomListToolBar/RoomListToolBar.vue';

import filteredItems from '@/utils/filter-items';

import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';

export default {
    name: 'RoomsList',
    components: {
        Loader,
        RoomsSearch,
        RoomContent,
        SvgIcon,
        RoomListToolBar,
    },

    props: {
        currentUserId: { type: [String, Number], required: true },
        textMessages: { type: Object, required: true },
        showRoomsList: { type: Boolean, required: true },
        showSearch: { type: Boolean, required: true },
        showAddRoom: { type: Boolean, required: true },
        textFormatting: { type: Object, required: true },
        linkOptions: { type: Object, required: true },
        isMobile: { type: Boolean, required: true },
        rooms: { type: Array, required: true },
        loadingRooms: { type: Boolean, required: true },
        roomsLoaded: { type: Boolean, required: true },
        room: { type: Object, required: true },
        customSearchRoomEnabled: { type: [Boolean, String], default: false },
        roomActions: { type: Array, required: true },
        scrollDistance: { type: Number, required: true },
    },

    emits: ['add-room', 'search-room', 'room-action-handler', 'loading-more-rooms', 'fetch-room', 'fetch-more-rooms'],

    data() {
        return {
            filteredRooms: this.rooms || [],
            observer: null,
            showLoader: true,
            loadingMoreRooms: false,
            selectedRoomId: '',
            counterVal: 0,
            searchParam: '',
            valueSearch: '',
        };
    },

    watch: {
        rooms: {
            deep: true,
            handler(newVal, oldVal) {
                this.filteredRooms = newVal;
                if (newVal.length !== oldVal.length || this.roomsLoaded) {
                    this.loadingMoreRooms = false;
                }
            },
        },
        loadingRooms(val) {
            if (!val) {
                setTimeout(() => this.initIntersectionObserver());
                if (this.searchParam !== '' && this.valueSearch !== '') {
                    this.$emit('search-room', this.searchParam);
                }
            }
        },
        loadingMoreRooms(val) {
            this.$emit('loading-more-rooms', val);
        },
        roomsLoaded: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.loadingMoreRooms = false;
                    if (!this.loadingRooms) {
                        this.showLoader = false;
                    }
                }
            },
        },
        room: {
            immediate: true,
            handler(val) {
                if (val && !this.isMobile) this.selectedRoomId = val.roomId;
            },
        },
    },

    methods: {
        initIntersectionObserver() {
            if (this.observer) {
                // this.showLoader = true;
                this.observer.disconnect();
            }
            // this.showLoader = true;
            const loader = this.$el.querySelector('#infinite-loader-rooms');

            if (loader) {
                const options = {
                    root: this.$el.querySelector('#rooms-list'),
                    rootMargin: `${this.scrollDistance}px`,
                    threshold: 0,
                };

                this.observer = new IntersectionObserver((entries) => {
                    if (entries[0].isIntersecting) {
                        this.loadMoreRooms();
                    }
                }, options);

                this.observer.observe(loader);
            }
        },
        onParamValue(v) {
            this.valueSearch = v;
        },
        searchRoom(ev) {
            this.valueSearch = ev.target.value;
            if (this.customSearchRoomEnabled) {
                // this.$emit("search-room", ev.target.value);
                this.$emit('search-room', this.valueSearch);
            } else {
                this.filteredRooms = filteredItems(
                    this.rooms,
                    'roomName',
                    this.valueSearch,
                    // ev.target.value
                );
            }
        },
        openRoom(room) {
            if (room.roomId === this.room.roomId && !this.isMobile) return;
            if (!this.isMobile) this.selectedRoomId = room.roomId;

            this.$emit('fetch-room', { room });
            this.$emit('room-changed', { room });
        },
        loadMoreRooms() {
            if (this.loadingMoreRooms) return;

            if (this.roomsLoaded) {
                this.loadingMoreRooms = false;
                this.showLoader = false;
                return;
            }

            this.$emit('fetch-more-rooms');
            this.loadingMoreRooms = true;
        },
        focusSearchInput() {
            this.$nextTick(() => {
                this.$refs.roomsSearch.$emit('focus-input');
            });
        },
    },

    mounted() {
        // Verificar si hay parametro en la URL
        const search = this.$route.fullPath.split('?search=')[1];

        if (search) {
            this.searchParam = search;
        }

        this.$nextTick(() => {
            this.initIntersectionObserver();
        });
        this.focusSearchInput();
    },
};
</script>
