var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vac-format-message-wrapper",class:{ 'vac-text-ellipsis': _vm.singleLine }},[_vm._l((_vm.parsedMessage),function(message,i){return _c('div',{key:i},[(message.markdown)?_c('div',{staticClass:"markdown",domProps:{"innerHTML":_vm._s(message.value)},on:{"click":_vm.openTag}}):_c('div',{staticClass:"vac-format-container",class:{ 'vac-text-ellipsis': _vm.singleLine }},[_c(message.url ? 'a' : 'span',{tag:"component",class:{
                        'vac-text-ellipsis': _vm.singleLine,
                        'vac-text-tag': !_vm.singleLine && !_vm.reply && message.tag,
                    },attrs:{"href":message.href,"target":message.href ? _vm.linkOptions.target : null,"rel":message.href ? _vm.linkOptions.rel : null}},[(_vm.deleted)?[_vm._t(_vm.roomList ? 'deleted-icon-room_' + _vm.roomId : 'deleted-icon_' + _vm.messageId,function(){return [_c('svg-icon',{staticClass:"vac-icon-deleted",class:{ 'vac-icon-deleted-room': _vm.roomList },attrs:{"name":"deleted"}})]}),_vm._v(" "+_vm._s(_vm.textMessages.MESSAGE_DELETED)+" ")]:(message.url && message.image)?[_c('div',{staticClass:"vac-image-link-container"},[_c('div',{staticClass:"vac-image-link",style:({
                                    'background-image': `url('${message.value}')`,
                                    height: message.height,
                                })})]),_c('div',{staticClass:"vac-image-link-message"},[_vm._v(" "+_vm._s(message.value)+" ")])]:[_vm._v(" "+_vm._s(message.value)+" ")]],2)],1)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }