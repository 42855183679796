<template>
    <div class="pagination-container">
        <!-- Add the new text showing the range -->
        <div class="pagination-range" v-if="showRange">
            <span class="md-body-1"
                ><strong>{{ from }}</strong> to <strong>{{ to }}</strong> of <strong>{{ total }}</strong></span
            >
        </div>

        <ul class="pagination" :class="paginationClass">
            <!-- First page button -->
            <li class="page-item first-page" :class="{ disabled: value === 1, 'no-arrows': noArrows }">
                <a class="page-link" aria-label="First" @click="goToFirstPage">
                    <i class="material-symbols-outlined">keyboard_double_arrow_left</i>
                </a>
            </li>
            <!-- Previous page button -->
            <li class="page-item prev-page" :class="{ disabled: value === 1, 'no-arrows': noArrows }">
                <a class="page-link" aria-label="Previous" @click="prevPage">
                    <i class="material-symbols-outlined">chevron_left</i>
                </a>
            </li>

            <!-- Improved page numbers section -->
            <li class="page-item" :class="{ active: value === 1 }">
                <a class="page-link" @click="changePage(1)">
                    <span class="md-body-1">1</span>
                </a>
            </li>
            <li class="page-item disabled" v-if="startPage > 2">
                <span class="page-link">...</span>
            </li>
            <li class="page-item" v-for="page in displayedPages" :key="page" :class="{ active: value === page }">
                <a class="page-link" @click="changePage(page)">
                    <span class="md-body-1">{{ page }}</span>
                </a>
            </li>
            <li class="page-item disabled" v-if="endPage < totalPages - 1">
                <span class="page-link">...</span>
            </li>
            <li class="page-item" v-if="totalPages > 1" :class="{ active: value === totalPages }">
                <a class="page-link" @click="changePage(totalPages)">
                    <span class="md-body-1">{{ totalPages }}</span>
                </a>
            </li>

            <!-- Next page button -->
            <li class="page-item page-pre next-page" :class="{ disabled: value === totalPages, 'no-arrows': noArrows }">
                <a class="page-link" aria-label="Next" @click="nextPage">
                    <i class="material-symbols-outlined">chevron_right</i>
                </a>
            </li>
            <!-- Last page button -->
            <li class="page-item last-page" :class="{ disabled: value === totalPages, 'no-arrows': noArrows }">
                <a class="page-link" aria-label="Last" @click="goToLastPage">
                    <i class="material-symbols-outlined">double_arrow</i>
                </a>
            </li>
        </ul>

        <!-- Updated Go to section -->
        <div class="pagination-go-to">
            <span class="md-body-1">Go to page</span>
            <input type="number" v-model.number="goToPage" min="1" :max="totalPages" @keyup.enter="goToInputPage" />
            <button @click="goToInputPage">
                <div class="md-body-1">Go</div>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'pagination',
    props: {
        type: {
            type: String,
            default: 'primary',
            validator: (value) => {
                return ['default', 'primary', 'danger', 'success', 'warning', 'info', 'rose'].includes(value);
            },
        },
        noArrows: Boolean,
        pageCount: {
            type: Number,
            default: 0,
        },
        perPage: {
            type: Number,
            default: 10,
        },
        total: {
            type: Number,
            default: 0,
        },
        value: {
            type: Number,
            default: 1,
        },
        from: {
            type: Number,
            default: 1,
        },
        to: {
            type: Number,
            default: 10,
        },
        showRange: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        paginationClass() {
            return `pagination-${this.type}`;
        },
        totalPages() {
            if (this.pageCount > 0) return this.pageCount;
            if (this.total > 0) {
                return Math.ceil(this.total / this.perPage);
            }
            return 1;
        },
        startPage() {
            return Math.max(2, this.value - 1);
        },
        endPage() {
            return Math.min(this.totalPages - 1, this.value + 1);
        },
        displayedPages() {
            const pages = [];
            for (let i = this.startPage; i <= this.endPage; i++) {
                pages.push(i);
            }
            return pages;
        },
    },
    data() {
        return {
            defaultPagesToDisplay: 5,
            goToPage: null,
        };
    },
    methods: {
        changePage(item) {
            this.$emit('input', item);
        },
        nextPage() {
            if (this.value < this.totalPages) {
                this.$emit('input', this.value + 1);
            }
        },
        prevPage() {
            if (this.value > 1) {
                this.$emit('input', this.value - 1);
            }
        },
        goToFirstPage() {
            if (this.value !== 1) {
                this.$emit('input', 1);
            }
        },
        goToLastPage() {
            if (this.value !== this.totalPages) {
                this.$emit('input', this.totalPages);
            }
        },
        goToInputPage() {
            if (this.goToPage && this.goToPage >= 1 && this.goToPage <= this.totalPages) {
                this.changePage(this.goToPage);
                this.goToPage = null; // Reset input after navigation
            }
        },
    },
    watch: {
        perPage() {
            this.$emit('input', 1);
        },
        total() {
            this.$emit('input', 1);
        },
    },
};
</script>
