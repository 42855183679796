<script>
import dashboardStatsCard from '@/components/Dashboard/dashboardStatsCard.vue';
import hotTopicCard from '@/components/Dashboard/HotTopicCard/DashboardHotTopicCard.vue';
import TopicsTable from '@/components/Dashboard/TopicsTable/DashboardTopicsTable.vue';
import verticalBusinessModal from '@/components/Dashboard/verticalBusinessModal/verticalBusinessModal.vue';
import dateRangebutton from '@/components/Dashboard/DateRangeButton/DashboardDateRangebutton.vue';
import thirdPartySkillInstaller from '@/components/Skills/ThirdPartySkills/ThirdPartySkillInstaller.vue';

import DashboardMetricsService from '@/services/dashboard/DashboardMetricsService';
import Firebase from '@/Firebase.js';
import { mapActions } from 'vuex';

export default {
    name: 'DashboardComponent',
    components: {
        dashboardStatsCard,
        hotTopicCard,
        TopicsTable,
        verticalBusinessModal,
        dateRangebutton,
    },
    data() {
        return {
            greeting: '',
            isLoading: false,
            currentRange: 'yesterday',
            rangeEndDate: null,
            rangeStartDate: null,
            dashboardConfiguration: [
                {
                    name: 'Conversations',
                    data: {
                        total: '0',
                        time_series: [],
                    },
                    tooltip: 'Total number of conversations over time',
                },
                {
                    name: 'Messages',
                    data: {
                        total: '0',
                        time_series: [],
                    },
                    tooltip: 'Total messages sent over time',
                },
                // {
                //     name: 'Client Satisfaction',
                //     data: {
                //         total: '0',
                //         time_series: [],
                //     },
                //     tooltip: 'Client satisfaction ratings over time',
                // },
                {
                    name: 'New customers',
                    data: {
                        total: '0',
                        time_series: [],
                    },
                    tooltip: 'Number of new customers acquired over time',
                },
                {
                    name: 'Recurrent customers',
                    data: {
                        total: '0',
                        time_series: [],
                    },
                    tooltip: 'Number of new customers acquired over time',
                },
                {
                    name: 'Messages per conversation',
                    data: {
                        total: '0',
                        time_series: [],
                    },
                    tooltip: 'Average number of messages per conversation over time',
                },
                {
                    name: 'Audio messages',
                    data: {
                        total: '0',
                        time_series: [],
                    },
                    tooltip: 'Total number of audio messages sent over time',
                },
                {
                    name: 'Image messages',
                    data: {
                        total: '0',
                        time_series: [],
                    },
                    tooltip: 'Total number of image messages sent over time',
                },
            ],
        };
    },
    computed: {
        firstName() {
            return this.$store.state.user.user.username.split(' ')[0];
        },
        accountHasBusinessType() {
            return this.$store.state.user.account.business_type !== null;
        },
        isShopifyInstaller() {
            const query = this.$route.query;
            return Object.keys(query).length > 0 && query.source === 'Shopify';
        },
        showOnboarding() {
            return !this.accountHasBusinessType && !this.isShopifyInstaller;
        },
    },

    watch: {
        currentRange(newValue) {
            if (newValue !== 'custom_range') {
                this.rangeEndDate = null;
                this.rangeStartDate = null;
                this.getCardsData();
            }
        },
    },

    methods: {
        ...mapActions('user', ['actionGetAccount']),

        ...mapActions('modalStore', ['openModal']),

        setGreeting() {
            const currentHour = new Date().getHours();
            if (currentHour < 12) {
                this.greeting = 'Good morning';
            } else if (currentHour < 18) {
                this.greeting = 'Good afternoon';
            } else {
                this.greeting = 'Good evening';
            }
        },

        navigateTo(route) {
            this.$router.push(route);
        },

        async getConversations() {
            try {
                const conversationStats = this.dashboardConfiguration.find((stat) => stat.name === 'Conversations');
                const params =
                    this.currentRange === 'custom_range'
                        ? [null, this.rangeStartDate, this.rangeEndDate]
                        : [this.currentRange];
                const response = await DashboardMetricsService.getTotalConversationsByDate(...params);
                this.$set(conversationStats, 'data', response.data);
            } catch (error) {
                console.error('Error getting conversations: ', error);
            }
        },

        async getMessages() {
            try {
                const messageStats = this.dashboardConfiguration.find((stat) => stat.name === 'Messages');
                const params =
                    this.currentRange === 'custom_range'
                        ? [null, this.rangeStartDate, this.rangeEndDate]
                        : [this.currentRange];
                const response = await DashboardMetricsService.getTotalMessagesByDate(...params);
                this.$set(messageStats, 'data', response.data);
            } catch (error) {
                console.error('Error getting messages: ', error);
            }
        },

        async getNewCustomers() {
            try {
                const newCustomerStats = this.dashboardConfiguration.find((stat) => stat.name === 'New customers');
                const params =
                    this.currentRange === 'custom_range'
                        ? [null, this.rangeStartDate, this.rangeEndDate]
                        : [this.currentRange];
                const response = await DashboardMetricsService.getTotalNewCustomersByDate(...params);
                this.$set(newCustomerStats, 'data', response.data);
            } catch (error) {
                console.error('Error getting new customers: ', error);
            }
        },

        async getClientSatisfaction() {
            try {
                const satisfactionStats = this.dashboardConfiguration.find(
                    (stat) => stat.name === 'Client Satisfaction',
                );
                const params =
                    this.currentRange === 'custom_range'
                        ? [null, this.rangeStartDate, this.rangeEndDate]
                        : [this.currentRange];
                const response = await DashboardMetricsService.getSatisfactionByDate(...params);
                this.$set(satisfactionStats, 'data', response.data);
            } catch (error) {
                console.error('Error getting client satisfaction: ', error);
            }
        },

        async getRecurrentCustomers() {
            try {
                const recurrentCustomerStats = this.dashboardConfiguration.find(
                    (stat) => stat.name === 'Recurrent customers',
                );
                const params =
                    this.currentRange === 'custom_range'
                        ? [null, this.rangeStartDate, this.rangeEndDate]
                        : [this.currentRange];
                const response = await DashboardMetricsService.getTotalRecurrentCustomersByDate(...params);
                this.$set(recurrentCustomerStats, 'data', response.data);
            } catch (error) {
                console.error('Error getting recurrent customers: ', error);
            }
        },

        async getMessagesPerConversation() {
            try {
                const messagesPerConversationStats = this.dashboardConfiguration.find(
                    (stat) => stat.name === 'Messages per conversation',
                );
                const params =
                    this.currentRange === 'custom_range'
                        ? [null, this.rangeStartDate, this.rangeEndDate]
                        : [this.currentRange];
                const response = await DashboardMetricsService.getAvgMessagePerConversationByDate(...params);
                this.$set(messagesPerConversationStats, 'data', response.data);
            } catch (error) {
                console.error('Error getting messages per conversation: ', error);
            }
        },

        async getImageMessages() {
            try {
                const imageMessageStats = this.dashboardConfiguration.find((stat) => stat.name === 'Image messages');
                const params =
                    this.currentRange === 'custom_range'
                        ? [null, this.rangeStartDate, this.rangeEndDate]
                        : [this.currentRange];
                const response = await DashboardMetricsService.getTotalImageMessagesByDate(...params);
                this.$set(imageMessageStats, 'data', response.data);
            } catch (error) {
                console.error('Error getting image messages: ', error);
            }
        },

        async getAudioMessages() {
            try {
                const audioMessageStats = this.dashboardConfiguration.find((stat) => stat.name === 'Audio messages');
                const params =
                    this.currentRange === 'custom_range'
                        ? [null, this.rangeStartDate, this.rangeEndDate]
                        : [this.currentRange];
                const response = await DashboardMetricsService.getTotalAudioMessagesByDate(...params);
                this.$set(audioMessageStats, 'data', response.data);
            } catch (error) {
                console.error('Error getting audio messages: ', error);
            }
        },

        async getCardsData() {
            try {
                this.isLoading = true;
                await Promise.all([
                    this.getConversations(),
                    this.getMessages(),
                    // this.getClientSatisfaction(),
                    this.getNewCustomers(),
                    this.getRecurrentCustomers(),
                    this.getMessagesPerConversation(),
                    this.getImageMessages(),
                    this.getAudioMessages(),
                ]).finally(() => {
                    this.isLoading = false;
                });
            } catch (error) {
                console.error('Error getting cards data:', error);
            }
        },

        handleDateRangeChanges(currentRange, rangeStartDate, dateEndRange) {
            this.currentRange = currentRange;
            this.rangeStartDate = rangeStartDate;
            this.rangeEndDate = dateEndRange;
            this.getCardsData();
        },

        hasShopifySkill() {
            const shopifySKillId = 'd74f8439-bda9-420a-8b20-cbb80e5f7510';

            const activeSkills = this.$store.getters['agentSettings/agentSettings'].skills;

            const hasShopifySkill = activeSkills.some((skill) => skill.skill_id === shopifySKillId);

            return hasShopifySkill;
        },

        handleQueryParams() {
            if (!this.isShopifyInstaller) return;

            if (this.hasShopifySkill()) {
                this.$router.push('/skills/details/d74f8439-bda9-420a-8b20-cbb80e5f7510');
                return;
            }

            const query = this.$route.query;
            this.openModal({
                component: thirdPartySkillInstaller,
                props: { skillInstallParams: query },
                onEvent: (eventName, eventPayload) => {
                    if (eventName === 'third-party-skill-installed') {
                        this.refreshAccount();
                        this.refreshAgentSettings();
                        this.$router.push(`/skills/details/${eventPayload}`);
                    }
                },
            });
        },

        async refreshAccount() {
            await this.actionGetAccount();
        },

        async refreshAgentSettings() {
            await this.$store.dispatch('agentSettings/updateAgentSetting');
        },
    },

    mounted() {
        this.setGreeting();
        this.getCardsData();
        this.handleQueryParams();
        Firebase.requestPermission();
    },
};
</script>

<template>
    <div class="custom-main-panel">
        <div>
            <md-progress-bar v-if="isLoading" md-mode="indeterminate"></md-progress-bar>
        </div>
        <div class="md-layout custom-content-panel">
            <div class="md-layout-item md-size-100">
                <div class="md-headline">{{ greeting }}, {{ firstName }} 👋</div>
                <div class="md-body-1">Here's how Lixsa performed today.</div>
            </div>
            <div class="custom-md-layout-grid md-layout-item md-size-100 dashboard__action-bar">
                <date-rangebutton
                    @predefined-range="
                        (range) => {
                            currentRange = range;
                        }
                    "
                    @custom-range="handleDateRangeChanges"
                ></date-rangebutton>
                <md-button v-if="currentRange !== 'custom_range'" @click="getCardsData" class="md-just-icon">
                    <i class="material-symbols-outlined"> refresh </i>
                </md-button>
            </div>

            <div
                v-for="stat in dashboardConfiguration"
                class="custom-md-layout-grid md-layout-item md-size-33 md-small-size-100"
            >
                <dashboardStatsCard
                    v-if="stat.name !== 'Client Satisfaction'"
                    :title="stat.name"
                    :quantity="stat.data.total"
                    :tooltip="stat.tooltip"
                />
                <dashboard-stats-card
                    v-else
                    :title="stat.name"
                    :quantity-in-percentage="stat.data.total"
                    :tooltip="stat.tooltip"
                />
            </div>
        </div>

        <verticalBusinessModal v-if="showOnboarding" />
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.custom-main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.custom-content-panel {
    margin-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 1278px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 959px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.custom-progress-bar {
    height: 15px;
    border-radius: 3px;
    margin-bottom: 6px;
    margin-top: 15px;
}

.channel-list-item {
    display: flex;
    align-items: center;
    i {
        margin-right: 6px;
    }

    .custom-active-icon {
        color: $brand-primary;
    }

    .custom-unactive-icon {
        color: $gray-color;
    }
}

.button-container {
    position: absolute;
    bottom: 15px; /* Distance from the bottom of the card */
    left: 15px; /* Distance from the left of the card */
}

.custom-md-layout-grid {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.custom-title {
    margin-bottom: 20px;
}

.dashboard__action-bar {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: space-between;
}
</style>
