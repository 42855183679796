import BaseDto from '../../_common/BaseDto';

/**
 * @class SubscriptionPaymentLinkRequestBuilder
 * @description Builder class for SubscriptionPaymentLinkRequest
 */
class SubscriptionPaymentLinkRequestBuilder {
    /**
     * @param {string} subscriptionPlan - The subscription plan type.
     * @returns {SubscriptionPaymentLinkRequestBuilder}
     */
    withSubscriptionPlan(subscriptionPlan) {
        this.subscription_plan = subscriptionPlan;
        return this;
    }

    /**
     * @param {string} toltioReferral - The Toltio referral code.
     * @returns {SubscriptionPaymentLinkRequestBuilder}
     */
    withToltioReferral(toltioReferral) {
        this.toltio_referral = toltioReferral;
        return this;
    }

    /**
     * @param {string} successUrl - The URL to redirect to on successful payment.
     * @returns {SubscriptionPaymentLinkRequestBuilder}
     */
    withSuccessUrl(successUrl) {
        this.success_url = successUrl;
        return this;
    }

    /**
     * @param {string} cancelUrl - The URL to redirect to if the payment is canceled.
     * @returns {SubscriptionPaymentLinkRequestBuilder}
     */
    withCancelUrl(cancelUrl) {
        this.cancel_url = cancelUrl;
        return this;
    }

    /**
     * @returns {SubscriptionPaymentLinkRequest} A new instance of SubscriptionPaymentLinkRequest.
     */
    build() {
        return new SubscriptionPaymentLinkRequest(this);
    }
}

/**
 * @class SubscriptionPaymentLinkRequest
 * @extends BaseDto
 * @description Represents a request for creating a subscription payment link.
 */
class SubscriptionPaymentLinkRequest extends BaseDto {
    /**
     * @constructor
     * @param {SubscriptionPaymentLinkRequestBuilder} builder - The builder object containing the request data.
     */
    constructor(builder) {
        super();
        this.subscription_plan = builder.subscription_plan;
        this.toltio_referral = builder.toltio_referral;
        this.success_url = builder.success_url;
        this.cancel_url = builder.cancel_url;
    }

    /**
     * @readonly
     * @static
     * @type {typeof SubscriptionPaymentLinkRequestBuilder}
     * @description Getter for the Builder class.
     */
    static get Builder() {
        return SubscriptionPaymentLinkRequestBuilder;
    }
}

export default SubscriptionPaymentLinkRequest;
