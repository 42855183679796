<script>
import { mapGetters } from 'vuex';
import stripeApiRepository from '@/services/stripe/StripeApiRepository';
import PackPaymentLinkRequest from '@/services/stripe/Dto/PackPaymentLinkRequest';
import ConversationsUsageProgressBar from '../ConversationsUsageProgressBar.vue';

export default {
    name: 'BillingSectionStripe',
    components: {
        ConversationsUsageProgressBar,
    },
    data() {
        return {
            isCreatingCustomerPortal: false,
            isCreatingPackCheckoutLink: false,
            isCreatingCancelPlanLink: false,
            isCreatingUpdatePlanLink: false,
        };
    },
    computed: {
        ...mapGetters('membership', [
            'consumedConversations',
            'totalConversations',
            'membershipPlanName',
            'isInTrialPeriod',
            'cancelAtPeriodEnd',
            'subscriptionEndDate',
            'subscriptionTrialEndDate',
            'cancelAt',
            'membership',
        ]),
        ...mapGetters('user', ['toltReferral']),
    },
    methods: {
        formatDate(timestamp) {
            const options = { month: 'long', day: 'numeric' };
            const date = new Date(timestamp * 1000);
            return date.toLocaleDateString('en-US', options);
        },
        async openPackCheckoutLink() {
            this.isCreatingPackCheckoutLink = true;
            try {
                const request = new PackPaymentLinkRequest.Builder()
                    .withToltioReferral(this.toltReferral)
                    .withSuccessUrl(`${window.location.origin}/settings/billing`)
                    .withCancelUrl(`${window.location.origin}/settings/billing`)
                    .build();

                const packPaymentLink = await stripeApiRepository.createPackPaymentLink(this.membership.uuid, request);
                window.open(packPaymentLink, '_blank');
            } catch (error) {
                console.error('Error creating payment link: ' + error);
            } finally {
                this.isCreatingPackCheckoutLink = false;
            }
        },
        async openCustomerPortal() {
            this.isCreatingCustomerPortal = true;
            try {
                const customerPortalURL = await stripeApiRepository.createCustomerPortalLink(
                    this.membership.uuid,
                    `${window.location.origin}/settings/billing`,
                );
                window.open(customerPortalURL, '_blank');
            } catch (error) {
                console.error('Error creating customer portal: ' + error);
            } finally {
                this.isCreatingCustomerPortal = false;
            }
        },
        async openCancelPlanPortal() {
            this.isCreatingCancelPlanLink = true;
            try {
                const customerPortalURL = await stripeApiRepository.createCancelLink(
                    this.membership.uuid,
                    `${window.location.origin}/settings/billing`,
                );
                window.open(customerPortalURL, '_blank');
            } catch (error) {
                console.error('Error creating cancel plan portal: ' + error);
            } finally {
                this.isCreatingCancelPlanLink = false;
            }
        },
        async openUpdatePlanPortal() {
            this.isCreatingUpdatePlanLink = true;
            try {
                const customerPortalURL = await stripeApiRepository.createUpdatePlanLink(
                    this.membership.uuid,
                    `${window.location.origin}/settings/billing`,
                );
                window.open(customerPortalURL, '_blank');
            } catch (error) {
                console.error('Error creating update plan portal: ' + error);
            } finally {
                this.isCreatingUpdatePlanLink = false;
            }
        },
        capitalizeFirstLetter(str) {
            if (typeof str !== 'string' || str.length === 0) {
                return '';
            }
            str = str.toLowerCase();
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
    },
};
</script>

<template>
    <div class="billing-section-stripe">
        <md-card>
            <md-card-header>
                <div class="md-title bold">
                    <i class="material-symbols-outlined"> package_2 </i>
                    Plan {{ capitalizeFirstLetter(membershipPlanName) }}
                </div>
                <p v-if="!isInTrialPeriod && !cancelAtPeriodEnd" class="md-caption">
                    Renews on {{ formatDate(subscriptionEndDate) }}
                </p>
                <p v-else-if="!cancelAtPeriodEnd" class="md-caption">
                    Your trial ends on {{ formatDate(subscriptionTrialEndDate) }}
                </p>
                <p v-if="cancelAtPeriodEnd" class="md-caption billing-section-stripe__cancel-message">
                    Your plan will cancel at {{ formatDate(cancelAt) }}.
                    <strong> Click on billing to renew the plan </strong>
                </p>
            </md-card-header>

            <md-card-content>
                <ConversationsUsageProgressBar />

                <div class="action-buttons">
                    <md-button class="md-outline md-primary" @click="openUpdatePlanPortal">
                        <md-progress-spinner
                            v-if="isCreatingUpdatePlanLink"
                            :md-diameter="12"
                            :md-stroke="2"
                            class="md-primary"
                            md-mode="indeterminate"
                        ></md-progress-spinner>
                        <i v-else class="material-symbols-outlined"> package_2 </i>
                        Update Plan
                    </md-button>
                    <md-button class="md-outline md-primary" @click="openPackCheckoutLink">
                        <md-progress-spinner
                            v-if="isCreatingPackCheckoutLink"
                            :md-diameter="12"
                            :md-stroke="2"
                            class="md-primary"
                            md-mode="indeterminate"
                        ></md-progress-spinner>
                        <i v-else class="material-symbols-outlined"> chat_bubble </i>
                        Buy More Conversations
                    </md-button>
                </div>
            </md-card-content>

            <md-card-actions>
                <md-button v-if="!cancelAtPeriodEnd" @click="openCancelPlanPortal" class="md-danger md-simple">
                    <md-progress-spinner
                        v-if="isCreatingCancelPlanLink"
                        :md-diameter="12"
                        :md-stroke="2"
                        class="md-danger"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                    <span>Cancel Subscription</span>
                </md-button>
                <md-button class="md-primary md-raised" @click="openCustomerPortal">
                    <md-progress-spinner
                        v-if="isCreatingCustomerPortal"
                        :md-diameter="12"
                        :md-stroke="2"
                        class="md-white"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                    <i v-else class="material-symbols-outlined"> credit_card </i>
                    Manage Billing
                </md-button>
            </md-card-actions>
        </md-card>
    </div>
</template>

<style scoped lang="scss">
.billing-section-stripe {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .md-card-header {
        padding: 16px;

        .md-title {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .md-card-content {
        padding: 16px;
    }

    .action-buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        margin-bottom: 24px;
    }

    .md-card-actions {
        padding: 16px;
        border-top: 1px solid #e5e7eb;
        display: flex;
        justify-content: space-between;
    }

    .billing-section-stripe__cancel-message {
        color: #f44336;
    }

    .bold {
        font-weight: 600;
    }

    @media (max-width: 640px) {
        .action-buttons {
            grid-template-columns: 1fr;
        }

        .md-card-actions {
            flex-direction: column-reverse;
            gap: 16px;

            .md-button {
                width: 100%;
            }
        }
    }

    .md-progress-spinner {
        margin-right: 1rem;
    }
}
</style>
