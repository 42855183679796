<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'CancelSubscriptionShopify',
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters('membership', ['membership']),
        subscriptionDetails() {
            return {
                plan: this.capitalizeFirstLetter(this.membership.membership_plan),
                billingCycle: this.formatBillingCycle(this.membership.membership_duration_type),
                nextBillingDate: this.formatDate(this.membership.subscription_end_date),
            };
        },
    },
    methods: {
        ...mapActions('membership', ['getAccountMembership', 'cancelShopifySubscription']),
        ...mapActions('modalStore', ['closeModal']),
        capitalizeFirstLetter(string) {
            if (!string) return '';
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        },
        formatBillingCycle(cycle) {
            const cycleMap = {
                MONTH: 'Monthly',
                YEAR: 'Yearly',
                UNDEFINED: 'Forever',
            };
            return cycleMap[cycle] || cycle;
        },
        formatDate(timestamp) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            const date = new Date(timestamp * 1000);
            return date.toLocaleDateString('en-US', options);
        },
        async confirmCancellation() {
            this.isLoading = true;
            try {
                await this.cancelShopifySubscription(this.membership.uuid);
                await this.getAccountMembership();
                this.$toasted.success('Subscription successfully cancelled', {
                    position: 'bottom-center',
                    duration: 3000,
                });
                this.closeModal();
            } catch (error) {
                console.error('Error cancelling subscription:', error);
                this.$toasted.error('Failed to cancel subscription. Please try again.', {
                    position: 'bottom-center',
                    duration: 3000,
                });
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<template>
    <div class="cancel-subscription">
        <div class="cancel-subscription__header">
            <md-button @click="closeModal()" class="md-icon-button">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <md-content class="cancel-subscription__content">
            <div class="cancel-subscription__title-container">
                <span class="material-symbols-outlined cancel-subscription__icon">error</span>
                <p class="cancel-subscription__title md-title">Cancel Subscription</p>
            </div>
            <p class="md-body-1">
                This action cannot be undone.
                <strong
                    >Your plan will automatically downgrade to the free tier at the end of your current billing
                    period.</strong
                >
            </p>
            <p class="md-subheading bold">Subscription Details:</p>
            <div class="subscription-details">
                <div class="subscription-detail">
                    <span class="detail-label">Plan:</span>
                    <strong class="detail-value">{{ subscriptionDetails.plan }}</strong>
                </div>
                <div class="subscription-detail">
                    <span class="detail-label">Billing Cycle:</span>
                    <strong class="detail-value">{{ subscriptionDetails.billingCycle }}</strong>
                </div>
                <div class="subscription-detail">
                    <span class="detail-label">Next Billing Date:</span>
                    <strong class="detail-value">{{ subscriptionDetails.nextBillingDate }}</strong>
                </div>
            </div>
            <div class="cancel-subscription__actions">
                <md-button @click="confirmCancellation()" class="md-raised md-danger" :disabled="isLoading">
                    {{ isLoading ? 'Cancelling...' : 'Yes, Cancel Subscription' }}
                </md-button>
                <md-button @click="closeModal()" class="md-gray md-outline">No, Keep Subscription</md-button>
            </div>
        </md-content>
    </div>
</template>

<style lang="scss" scoped>
.cancel-subscription {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f5f5f5;
    width: 100%;
    max-width: 100%;

    @media (min-width: 768px) {
        width: 500px;
        max-width: 500px;
        margin: 0 auto;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: end;
        justify-content: flex-end;
        padding: 1rem 0.5rem 0 1rem;
        background-color: #ffffff;
        // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
    &__title {
        font-size: 22px;
        font-weight: 500;
        margin: 0;
        color: #f44336;
    }
    &__content {
        flex-grow: 1;
        padding: 0 2rem;
        overflow-y: auto;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 1rem;
        margin-bottom: 1rem;
        gap: 16px;
    }
}

.bold {
    font-weight: bold;
}

.subscription-details {
    margin-bottom: 24px;
}

.subscription-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0;
}

.detail-label {
    flex: 0 0 40%;
    text-align: left;
}

.detail-value {
    flex: 0 0 60%;
    text-align: right;
}

.cancel-subscription__title-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.cancel-subscription__icon {
    color: #f44336;
    font-size: 24px;
    margin-right: 8px;
}
</style>
