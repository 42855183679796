<template>
    <div class="conversations-usage">
        <div class="conversations-usage__header">
            <h3 class="conversations-usage__title">
                <i class="material-symbols-outlined conversations-usage__icon">chat_bubble</i>
                Conversation Usage
            </h3>
            <span class="conversations-usage__count">{{ consumedConversations }} / {{ totalConversations }}</span>
        </div>
        <md-progress-bar
            class="conversations-usage__progress-bar md-primary"
            md-mode="determinate"
            :md-value="conversationsPercentage"
        ></md-progress-bar>
        <p class="conversations-usage__caption">
            You have used {{ conversationsPercentage.toFixed(1) }}% of your available conversations
        </p>
        <div v-if="conversationsPercentage > 80" class="conversations-usage__alert">
            <i class="material-symbols-outlined conversations-usage__alert-icon">warning</i>
            <div class="conversations-usage__alert-content">
                <h4 class="conversations-usage__alert-title">Running low on conversations</h4>
                <p class="conversations-usage__alert-text">
                    Consider upgrading your plan or purchasing additional conversations.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ConversationsUsageProgressBar',
    computed: {
        ...mapGetters('membership', ['consumedConversations', 'totalConversations']),
        conversationsPercentage() {
            const percentage = (this.consumedConversations * 100) / this.totalConversations;
            return percentage > 0 ? Number(percentage.toFixed(1)) : Number(percentage);
        },
    },
};
</script>

<style scoped lang="scss">
.conversations-usage {
    margin-bottom: 24px;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 600;
    }

    &__icon {
        font-size: 1.2em;
    }

    &__progress-bar {
        height: 8px;
        border-radius: 4px;
        margin-bottom: 8px;
    }

    &__caption {
        font-size: 0.8em;
        color: rgba(0, 0, 0, 0.54);
    }

    &__alert {
        background-color: #fef3c7;
        border-left: 4px solid #f59e0b;
        padding: 16px;
        display: flex;
        align-items: flex-start;
        gap: 12px;
        margin-top: 16px;
        border-radius: 8px;
    }

    &__alert-icon {
        color: #f59e0b;
    }

    &__alert-content {
        flex: 1;
    }

    &__alert-title {
        margin-bottom: 4px;
        font-weight: 600;
    }

    &__alert-text {
        font-size: 0.9em;
    }
}
</style>
