<script>
import CircularProgressBar from '@/components/CircularProgressBar.vue';

function setSidebarShowEventListener() {
    document.querySelector('.sidebar').addEventListener('transitionend', function (event) {
        if (event.propertyName === 'width') {
            // Check if the sidebar is fully expanded
            if (getComputedStyle(event.target).width === '260px') {
                document.querySelector('.sidebar-subs-panel__call-to-action').style.display = 'flex';

                const sidebarPanel = document.querySelector('.sidebar-notifications-panel');
                if (sidebarPanel) {
                    sidebarPanel.style.left = '270px';
                }
            }
        }
    });
}

function setSidebarHideEventListener() {
    document.querySelector('.sidebar').addEventListener('mouseleave', function () {
        if (this.closest('.sidebar-mini')) {
            document.querySelector('.sidebar-subs-panel__call-to-action').style.display = 'none';

            const sidebarPanel = document.querySelector('.sidebar-notifications-panel');
            if (sidebarPanel) {
                sidebarPanel.style.left = '90px';
            }
        }
    });
}

export default {
    name: 'SidebarFreePlanPanel',
    components: { CircularProgressBar },
    computed: {
        conversationsPercentage() {
            const percentage =
                (this.$store.getters['membership/consumedConversations'] * 100) /
                this.$store.getters['membership/totalConversations'];
            return percentage > 0 ? percentage.toFixed(0) : percentage;
        },

        remainingConversationsPercentage() {
            return 100 - this.conversationsPercentage;
        },
    },
    methods: {
        goToPage(route) {
            if (this.$route.path !== route) {
                this.$router.push(route);
            }
        },
    },
    mounted() {
        setSidebarShowEventListener();
        setSidebarHideEventListener();
    },
};
</script>

<template>
    <div class="sidebar-subs-panel">
        <p>FREE</p>
        <!-- <circular-progress-bar :value="remainingConversationsPercentage || 100" /> -->
        <div class="sidebar-subs-panel__call-to-action">
            <!-- <div class="md-caption">You are currently in a free plan</div>
            <div class="md-caption">Upgrade to unlock all the features</div> -->
            <circular-progress-bar :value="remainingConversationsPercentage || 100" />
            <div style="text-align: center" class="md-caption">
                You have consumed {{ conversationsPercentage || 0 }}% of your free conversations
            </div>
            <md-button
                @click="goToPage('/settings/billing')"
                class="md-primary md-sm md-block sidebar-subs-panel__call-to-action__button"
                >Upgrade</md-button
            >
        </div>
    </div>
</template>

<style scoped lang="scss">
.sidebar-subs-panel__call-to-action {
    padding: 0.5rem 0.5rem;
}
</style>
