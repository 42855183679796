<script>
import CatalogServiceV2 from '@/services/productCatalog/CatalogServiceV2';
import TheImageLoader from '@/components/TheImageLoader.vue';
import { mapActions } from 'vuex';
import storageService from '@/services/google/storageService';

export default {
    name: 'CatalogCreateItem',
    components: { TheImageLoader },
    emits: ['item-created'],
    data: () => ({
        isLoading: false,
        expandMetadata: false,
        form: {
            item_id: '',
            name: '',
            description: '',
            images: [],
            metadata: [],
            is_visible: true,
        },
    }),
    computed: {
        canSave() {
            return this.form.name !== '' && this.form.description !== '';
        },
    },
    methods: {
        ...mapActions('modalStore', ['closeModal']),

        updateImagesArray(updatedImagesArray) {
            this.form.images = [...updatedImagesArray];
        },

        updateFormMetadataWithSchema(attributes) {
            this.form.metadata = attributes.map((attribute) => ({
                attribute_name: attribute.name,
                attribute_value: this.getDefaultAttributeValue(attribute.type),
                attribute_type: attribute.type,
            }));
        },

        getDefaultAttributeValue(attributeType) {
            const defaultValues = {
                STRING: '',
                NUMBER: 0,
            };
            return defaultValues[attributeType] || '';
        },

        async loadSchema() {
            try {
                const metadataSchema = await CatalogServiceV2.getCatalog();
                this.updateFormMetadataWithSchema(metadataSchema.data.schema.attributes);
                // this.form.metadata = metadataSchema.data.attributes ? [...metadataSchema.data.attributes] : [];
            } catch (error) {
                console.error('Error loading schema: ', error);
                // this.showErrorToast('Error loading schema');
            }
        },

        async createItem() {
            this.isLoading = true;

            try {
                if (this.form.images.length > 0) {
                    const imagesURLs = await this.generateImagesURL();
                    this.form.images = [...imagesURLs];
                }
                await CatalogServiceV2.createItem(this.form);
                this.showSuccessToast('Item created!');
                // this.notifyParent();
                this.closeModal();
            } catch (error) {
                console.error('Error creating an item: ', error);
            } finally {
                this.isLoading = false;
            }
        },

        async generateImagesURL() {
            let imagesURLs = [];

            for (const image of this.form.images) {
                try {
                    const getStorageUrlResponse = await CatalogServiceV2.getStorageURL(image.type);
                    await storageService.uploadFileToGoogleCloud(getStorageUrlResponse.data, image, image.type);
                    imagesURLs.push(getStorageUrlResponse.data.split('?')[0]);
                } catch (error) {
                    console.error('Error processing image: ', error);
                }
            }

            return imagesURLs;
        },

        notifyParent() {
            this.$emit('item-created');
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
    beforeMount() {
        this.loadSchema();
    },
};
</script>

<template>
    <div class="dc-create-item">
        <div class="dc-create-item__close_bar">
            <md-button @click="closeModal()" class="md-just-icon"><md-icon>close</md-icon></md-button>
        </div>
        <md-dialog-content id="dialogContent">
            <!--      <div class="dcse__loader" v-if="baseCatalogSchema === null">-->
            <!--        <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>-->
            <!--      </div>-->

            <div class="dc-create-item__layout">
                <div class="md-headline">Item Creation</div>

                <md-field>
                    <label>Name*</label>
                    <md-input name="Name" v-model="form.name"></md-input>
                </md-field>

                <md-field>
                    <label>Description*</label>
                    <md-textarea name="Description" v-model="form.description" md-autogrow></md-textarea>
                </md-field>

                <div class="md-title">Upload Photos</div>

                <div>
                    <TheImageLoader @images-added="(args) => updateImagesArray(args)"></TheImageLoader>
                </div>

                <div @click="expandMetadata = !expandMetadata" class="md-title dc-create-item__metadata-toggle">
                    Metadata
                    <md-icon v-if="!expandMetadata">navigate_next</md-icon>
                    <md-icon v-else>expand_more</md-icon>
                </div>

                <md-field v-if="expandMetadata" v-for="(attribute, index) in form.metadata" :key="index">
                    <label>{{ attribute.attribute_name }}</label>
                    <md-input :name="attribute.attribute_name" v-model="attribute.attribute_value"></md-input>
                </md-field>
            </div>
        </md-dialog-content>
        <md-dialog-actions>
            <md-button @click="closeModal()">Close</md-button>
            <md-button v-if="isLoading" disabled>
                <md-progress-spinner :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
            </md-button>
            <md-button v-else class="md-primary" @click="createItem()" :disabled="!canSave">
                <span>Create</span>
            </md-button>
        </md-dialog-actions>
    </div>
</template>

<style scoped lang="scss">
.dc-create-item__close_bar {
    text-align: end;
}

.dc-create-item__layout {
    width: 50vw;
    max-height: 50vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.dc-create-item__metadata-toggle {
    cursor: pointer;
    margin-top: 10px;
}
</style>
