/**
 * BaseDto class represents a base Data Transfer Object.
 * It provides common functionality for data manipulation and serialization.
 */
class BaseDto {
    /**
     * Creates an instance of BaseDto.
     * @param {Object} [data={}] - The initial data to populate the DTO.
     */
    constructor(data = {}) {
        Object.assign(this, data);
    }

    /**
     * Converts the DTO to a JSON object, excluding undefined properties.
     * @returns {Object} A JSON representation of the DTO.
     */
    /* eslint-disable no-unused-vars */
    toJSON() {
        return Object.fromEntries(Object.entries(this).filter(([_, value]) => value !== undefined));
    }

    /**
     * Creates a new instance of the DTO from a JSON object.
     * @param {Object} json - The JSON object to create the DTO from.
     * @returns {BaseDto} A new instance of the DTO.
     */
    static fromJSON(json) {
        return new this(json);
    }
}

export default BaseDto;
