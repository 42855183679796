<template>
    <div class="main-apps-config-form">
        <div class="main-apps-config-form__header">
            <md-button @click="closeModal()" class="md-icon-button main-apps-config-form__close-button">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <md-content class="md-scrollbar main-apps-config-form__content">
            <h1 class="md-title main-apps-config-form__title">Configure your app</h1>
            <div class="main-apps-config-form__fields">
                <div v-for="(value, key) in configSchemaValues" :key="key" class="main-apps-config-form__field">
                    <md-field>
                        <label>{{ formatLabel(key) }}</label>
                        <md-input
                            v-model="formData[key]"
                            :required="true"
                            :type="isPasswordField(key) ? 'password' : 'text'"
                        ></md-input>
                    </md-field>
                </div>
            </div>
        </md-content>
        <md-card-actions class="main-apps-config-form__actions">
            <md-button
                @click="submitForm"
                class="md-primary md-raised main-apps-config-form__submit"
                :disabled="!isFormValid"
                >Submit</md-button
            >
        </md-card-actions>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'MainAppsConfigValuesForm',
    props: {
        configSchemaValues: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            formData: {},
        };
    },
    created() {
        // Initialize formData with empty strings for all configSchemaValues keys
        this.formData = Object.keys(this.configSchemaValues).reduce((acc, key) => {
            acc[key] = '';
            return acc;
        }, {});
    },
    computed: {
        isFormValid() {
            return Object.values(this.formData).every((value) => value.trim() !== '');
        },
    },
    methods: {
        ...mapActions('modalStore', ['closeModal']),
        formatLabel(key) {
            // Convert camelCase or snake_case to Title Case
            return key
                .replace(/([A-Z])/g, ' $1')
                .replace(/_/g, ' ')
                .replace(/^./, (str) => str.toUpperCase());
        },
        submitForm() {
            if (this.isFormValid) {
                this.$emit('modalEvent', { eventName: 'form-submitted', eventPayload: this.formData });
                this.closeModal();
            }
        },
        isPasswordField(key) {
            return key.toLowerCase().includes('password') || key.toLowerCase().includes('token');
        },
    },
};
</script>

<style lang="scss" scoped>
.main-apps-config-form {
    min-width: 400px;
    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 12px 6px 0;
        background-color: #ffffff;
    }

    &__content {
        padding: 0 16px 16px 16px;
        max-height: 400px;
        overflow-y: auto;
    }

    &__title {
        margin-bottom: 16px;
        margin-top: 0;
    }

    &__fields {
        display: flex;
        flex-direction: column;
    }

    &__field {
        margin-bottom: 16px;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        padding: 16px;
    }

    &__submit {
        min-width: 100px;
    }
}
</style>
