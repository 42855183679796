import axios from 'axios';
import store from '@/store';

import SubscriptionResponseData from './Dto/SubscriptionResponseData';
import CheckoutSessionResponseData from './Dto/CheckoutSessionResponseData';
import CustomerDetailsDto from './Dto/CustomerDetailsDto';
import LineItemDto from './Dto/LineItemDto';
// eslint-disable-next-line no-unused-vars
import PackPaymentLinkRequest from './Dto/PackPaymentLinkRequest';
// eslint-disable-next-line no-unused-vars
import SubscriptionPaymentLinkRequest from './Dto/SubscriptionPaymentLinkRequest';
const baseURL = `${process.env.VUE_APP_API_URL}`;

const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}) => {
    try {
        return store.dispatch('user/apiCall', async (token) => {
            const config = {
                method: method,
                url: `${baseURL}/accounts/${store.state.user.user.account_id}/stripe/${url}`,
                headers: getAuthHeader(token),
                params: { ...params },
                data: data,
            };
            return await axios(config);
        });
    } catch (error) {
        throw error;
    }
};

/**
 * Repository for Stripe API operations.
 * @namespace
 */
const stripeApiRepository = {
    /**
     * Fetch subscription data for a given membership ID.
     * @param {string} membershipId - The ID of the membership to fetch the subscription for.
     * @returns {Promise<SubscriptionResponseData>} A promise that resolves to a SubscriptionResponseData object.
     * @throws {Error} If the API call fails or the response is invalid.
     */
    getSubscription: async (membershipId) => {
        const url = `membership/${membershipId}`;
        const response = await apiRequest('GET', url);

        const subscriptionData = response.data;

        return new SubscriptionResponseData.Builder()
            .withId(subscriptionData.id)
            .withProductId(subscriptionData.product_id)
            .withProductName(subscriptionData.product_name)
            .withTrialEnd(subscriptionData.trial_end)
            .withStatus(subscriptionData.status)
            .withCancelAtPeriodEnd(subscriptionData.cancel_at_period_end)
            .withCancelAt(subscriptionData.cancel_at)
            .build();
    },

    /**
     * Fetch checkout session data for a given checkout session ID.
     * @param {string} checkoutSessionId - The ID of the checkout session to fetch.
     * @returns {Promise<CheckoutSessionResponseData>} A promise that resolves to a CheckoutSessionResponseData object.
     * @throws {Error} If the API call fails or the response is invalid.
     */
    getCheckoutSession: async (checkoutSessionId) => {
        const url = `checkout-session/${checkoutSessionId}`;
        const response = await apiRequest('GET', url);

        const sessionData = response.data || {};

        const customerDetails = sessionData.customer_details
            ? new CustomerDetailsDto.Builder()
                  .withEmail(sessionData.customer_details.email || '')
                  .withName(sessionData.customer_details.name || '')
                  .withPhone(sessionData.customer_details.phone || '')
                  .withAddress(sessionData.customer_details.address || '')
                  .build()
            : null;

        const lineItems = Array.isArray(sessionData.line_items)
            ? sessionData.line_items.map((item) =>
                  new LineItemDto.Builder()
                      .withDescription(item.description || '')
                      .withQuantity(item.quantity || 0)
                      .withPrice(item.price || 0)
                      .withCurrency(item.currency || '')
                      .build(),
              )
            : [];

        return new CheckoutSessionResponseData.Builder()
            .withId(sessionData.id || '')
            .withMode(sessionData.mode || '')
            .withStatus(sessionData.status || '')
            .withCurrency(sessionData.currency || '')
            .withAmountTotal(sessionData.amount_total || 0)
            .withAmountSubtotal(sessionData.amount_subtotal || 0)
            .withSuccessUrl(sessionData.success_url || '')
            .withCancelUrl(sessionData.cancel_url || '')
            .withPaymentStatus(sessionData.payment_status || '')
            .withExpiresAt(sessionData.expires_at || null)
            .withCustomerDetails(customerDetails)
            .withLineItems(lineItems)
            .withMetadata(sessionData.metadata || {})
            .build();
    },

    /**
     * Retrieves a customer portal link for a given membership.
     * @param {string} membershipId - The ID of the membership.
     * @param {string} returnUrl - The URL to return to after the customer portal session.
     * @returns {Promise<string>} A promise that resolves to a string containing the URL to the customer portal.
     * @throws {Error} If the API call fails or the response is invalid.
     */
    createCustomerPortalLink: async (membershipId, returnUrl) => {
        const params = { returnUrl };
        const url = `membership/${membershipId}/customer-portal-link`;
        const response = await apiRequest('POST', url, null, params);
        return response.data;
    },

    /**
     * Creates a cancel link for a given membership.
     * @param {string} membershipId - The ID of the membership.
     * @returns {Promise<string>} A promise that resolves to a string containing the URL to the cancel page.
     * @throws {Error} If the API call fails or the response is invalid.
     */
    createCancelLink: async (membershipId, returnUrl) => {
        const params = { returnUrl };
        const url = `membership/${membershipId}/cancel-link`;
        const response = await apiRequest('POST', url, null, params);
        return response.data;
    },

    /**
     * Creates an update plan link for a given membership.
     * @param {string} membershipId - The ID of the membership.
     * @returns {Promise<string>} A promise that resolves to a string containing the URL to the update plan page.
     * @throws {Error} If the API call fails or the response is invalid.
     */
    createUpdatePlanLink: async (membershipId, returnUrl) => {
        const params = { returnUrl };
        const url = `membership/${membershipId}/update-plan-link`;
        const response = await apiRequest('POST', url, null, params);
        return response.data;
    },

    /**
     * Creates a pack payment link for a given membership.
     * @param {string} membershipId - The ID of the membership.
     * @param {PackPaymentLinkRequest} request - The request object containing the payment link details.
     * @returns {Promise<string>} A promise that resolves to a string containing the URL to the pack payment page.
     * @throws {Error} If the API call fails or the response is invalid.
     */
    createPackPaymentLink: async (membershipId, request) => {
        const url = `membership/${membershipId}/pack-payment-link`;
        const response = await apiRequest('POST', url, request.toJSON());
        return response.data;
    },

    /**
     * Creates a subscription payment link for a given membership.
     * @param {string} membershipId - The ID of the membership.
     * @param {SubscriptionPaymentLinkRequest} request - The request object containing the payment link details.
     * @returns {Promise<string>} A promise that resolves to a string containing the URL to the subscription payment page.
     * @throws {Error} If the API call fails or the response is invalid.
     */
    createSubscriptionPaymentLink: async (membershipId, request) => {
        const url = `membership/${membershipId}/subscription-payment-link`;
        const response = await apiRequest('POST', url, request.toJSON());
        return response.data;
    },
};

export default stripeApiRepository;
