class StripeSubscriptionBuilder {
    constructor() {
        this.id = undefined;
        this.productId = undefined;
        this.productName = undefined;
        this.trialEnd = undefined;
        this.status = undefined;
        this.cancelAtPeriodEnd = undefined;
        this.cancelAt = undefined;
    }

    withId(id) {
        this.id = id;
        return this;
    }

    withProductId(productId) {
        this.productId = productId;
        return this;
    }

    withProductName(productName) {
        this.productName = productName;
        return this;
    }

    withTrialEnd(trialEnd) {
        this.trialEnd = trialEnd;
        return this;
    }

    withStatus(status) {
        this.status = status;
        return this;
    }

    withCancelAtPeriodEnd(cancelAtPeriodEnd) {
        this.cancelAtPeriodEnd = cancelAtPeriodEnd;
        return this;
    }

    withCancelAt(cancelAt) {
        this.cancelAt = cancelAt;
        return this;
    }

    build() {
        return new StripeSubscription(this);
    }
}

class StripeSubscription {
    constructor(builder) {
        this.id = builder.id;
        this.productId = builder.productId;
        this.productName = builder.productName;
        this.trialEnd = builder.trialEnd;
        this.status = builder.status;
        this.cancelAtPeriodEnd = builder.cancelAtPeriodEnd;
        this.cancelAt = builder.cancelAt;
    }

    static get Builder() {
        return StripeSubscriptionBuilder;
    }

    // You can add additional methods here if needed, for example:

    isTrialing() {
        return this.status === 'trialing';
    }

    // Method to create a StripeSubscription instance from a plain object
    static fromObject(obj) {
        return new StripeSubscription.Builder()
            .withId(obj.id)
            .withProductId(obj.productId)
            .withProductName(obj.productName)
            .withTrialEnd(obj.trialEnd)
            .withStatus(obj.status)
            .withCancelAtPeriodEnd(obj.cancelAtPeriodEnd)
            .withCancelAt(obj.cancelAt)
            .build();
    }

    // Method to convert the StripeSubscription instance to a plain object
    toObject() {
        return {
            id: this.id,
            productId: this.productId,
            productName: this.productName,
            trialEnd: this.trialEnd,
            status: this.status,
            cancelAtPeriodEnd: this.cancelAtPeriodEnd,
            cancelAt: this.cancelAt,
        };
    }
}

export default StripeSubscription;
