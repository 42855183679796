<script>
export default {
    name: 'sidebar-profile-button',
    computed: {
        firstLetterOfUsername() {
            if (this.$store.state.user.user.username && this.$store.state.user.user.username.length > 0) {
                return this.$store.state.user.user.username.charAt(0).toUpperCase();
            }
            return 'A';
        },

        isCopilotEnabled() {
            return this.$store.getters['user/isCopilotEnabled'];
        },

        accountName() {
            if (this.$store.state.user.account.shop_name && this.$store.state.user.account.shop_name.length > 0) {
                return this.$store.state.user.account.shop_name;
            }

            return this.$store.state.user.account.contact_name;
        },
    },

    methods: {
        logout() {
            this.$store
                .dispatch('user/logout')
                .then(() => {
                    if (this.$route.path !== '/') {
                        this.$router.push({ name: 'Login', query: { logout: 'true' } });
                    }
                })
                .catch((error) => {
                    this.error = error.message;
                });
        },

        goToPage(route) {
            if (this.$route.path !== route) {
                this.$router.push(route);
            }
        },

        openHelpCenter() {
            window.open(
                'https://wa.me/34601570569?text=Hi!%20I%20would%20like%20to%20know%20more%20about%20Lixsa',
                '_blank',
            ).focus;
        },

        toggleCopilot() {
            if (this.isCopilotEnabled) {
                this.$store.dispatch('user/disableCopilot');
            } else {
                this.$store.dispatch('user/enableCopilot');
            }
        },
    },
};
</script>

<template>
    <li class="sidebar-profile-button">
        <md-menu :mdCloseOnClick="true" md-size="big" md-direction="top-end" md-align-trigger>
            <md-list md-menu-trigger>
                <div class="sidebar-profile-button__content">
                    <md-avatar class="sidebar-profile-button__content__avatar md-avatar-icon md-small">
                        <img
                            v-if="$store.state.user.userProfilePicture"
                            :src="$store.state.user.userProfilePicture"
                            alt="Profile Picture"
                            referrerpolicy="no-referrer"
                        />
                        <span v-else>{{ firstLetterOfUsername }}</span>
                    </md-avatar>
                    <p>{{ accountName }}</p>
                    <i class="material-symbols-outlined sidebar-profile-button__content__more-icon">more_vert</i>
                </div>
            </md-list>
            <md-menu-content>
                <div class="sidebar-profile-button__menu">
                    <div class="sidebar-profile-button__menu-content">
                        <ul class="sidebar-profile-button__menu-items">
                            <li
                                @click="toggleCopilot"
                                class="sidebar-profile-button__menu-item md-body-1"
                                :class="{ 'sidebar-profile-button__menu-item--activated': isCopilotEnabled }"
                            >
                                <span class="material-symbols-outlined sidebar-profile-button__icon"> bolt </span>
                                <span
                                    >ILA
                                    <span v-if="isCopilotEnabled">Enabled</span>
                                    <span v-else>Disabled</span>
                                </span>
                                <i class="material-symbols-outlined sidebar-profile-button__icon"> info </i>
                                <md-tooltip md-direction="right"
                                    >Enable ILA (Intelligent Language Assistant) to improve the grammar and spelling of
                                    the messages you send to your customers in the customer support chat.</md-tooltip
                                >
                            </li>
                        </ul>
                        <md-divider class="sidebar-profile-button__divider"></md-divider>
                        <ul class="sidebar-profile-button__menu-items">
                            <li
                                @click="goToPage('/settings/account')"
                                class="sidebar-profile-button__menu-item md-body-1"
                            >
                                <i class="material-symbols-outlined sidebar-profile-button__icon"> settings </i>
                                Settings
                            </li>
                            <li
                                @click="goToPage('/settings/billing')"
                                class="sidebar-profile-button__menu-item md-body-1"
                            >
                                <i class="material-symbols-outlined sidebar-profile-button__icon"> credit_card </i>
                                Billing
                            </li>
                            <li @click="openHelpCenter" class="sidebar-profile-button__menu-item md-body-1">
                                <i class="material-symbols-outlined sidebar-profile-button__icon"> support </i>
                                Ask Lixsa
                            </li>
                        </ul>
                        <md-divider class="sidebar-profile-button__divider"></md-divider>
                        <ul class="sidebar-profile-button__menu-items">
                            <li @click="logout" class="sidebar-profile-button__menu-item md-body-1">
                                <i class="material-symbols-outlined sidebar-profile-button__icon"> logout </i>
                                Log out
                            </li>
                        </ul>
                    </div>
                </div>
            </md-menu-content>
        </md-menu>
    </li>
</template>

<style scoped lang="scss"></style>
