<template>
    <div class="card-details-customer">
        <div class="card-details-location">
            <div class="card-details-row">
                <div class="card-details-orders" @click="toggleShowDetailsOrder">
                    <div class="card-details-row-icono">
                        <svg-icon class="card-icon-height" name="description" />
                    </div>
                    <div class="card-details-row-number">
                        <div class="order-title">{{ order.name }}</div>
                        <div class="card-details-badges">
                            <span :class="['order-badges', `badge-${order.draft_order_creation_status.toLowerCase()}`]">
                                {{ removeUnderscores(order.draft_order_creation_status).toLocaleUpperCase() }}
                            </span>
                            <span :class="['order-badges', `badge-${order.draft_order_sync_status.toLowerCase()}`]">
                                {{ removeUnderscores(order.draft_order_sync_status).toLocaleUpperCase() }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import { getCurrencySymbol, formatNumberWithDecimals, getBadgeClass } from '@/utils/common.js';

export default {
    name: 'CustomerAssetsCardEcommerceDraftOrder',
    components: {
        SvgIcon,
    },
    props: {
        order: { type: [Object], required: true },
        showDetailsOrder: { type: [Boolean], required: true },
    },
    methods: {
        toggleShowDetailsOrder() {
            this.$emit('toggle-details-order', this.order);
        },
        removeUnderscores(value) {
            return value.replace(/_/g, ' ');
        },
        getBadgeClass,
        getCurrencySymbol,
        formatNumberWithDecimals,
    },
};
</script>
