<script>
import SubscriptionPricingCard from '@/components/Settings/Subscription/PricingCards/SubscriptionPricingCard.vue';
import shopifyBillingService from '@/services/shopify/shopifyBillingService.js';
import CancelSubscriptionShopify from '@/components/Settings/Subscription/BillingSection/BillingSectionShopify/cancelSubscriptionShopify.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'PricingSection',
    components: {
        SubscriptionPricingCard,
    },
    data() {
        return {
            pricingPlans: [
                {
                    title: 'Free',
                    pricePerMonth: 0,
                    features: [
                        'Free Forever',
                        '30 conversations',
                        'FAQ Support Business Knowledge',
                        'Voice Recognition',
                        'API Integration',
                    ],
                    isPopular: false,
                    isCurrentPlan: false,
                    duration: 'MONTH',
                },
                {
                    title: 'Startup',
                    pricePerMonth: 150,
                    features: [
                        '7 Days Free Trial',
                        '250 conversations',
                        'FAQ Support Business Knowledge',
                        'Voice Recognition',
                        'API Integration',
                    ],
                    isPopular: false,
                    isCurrentPlan: false,
                    duration: 'MONTH',
                },
                {
                    title: 'Scaling',
                    pricePerMonth: 250,
                    features: [
                        '7 Days Free Trial',
                        '500 conversations',
                        'All features in Start Up Plan',
                        'CSAT Score Advance',
                        'Multi Language',
                        'Sales Agent',
                        '5 members',
                    ],
                    isPopular: true,
                    isCurrentPlan: false,
                    duration: 'MONTH',
                },
                {
                    title: 'Pro',
                    pricePerMonth: 550,
                    features: [
                        '7 Days Free Trial',
                        '1200 conversations',
                        'All features in Scaling Plan',
                        'CSAT Real Time',
                        'Simultaneous Answers Unlimited',
                        'Sales Agent',
                        '10 members',
                    ],
                    isPopular: false,
                    isCurrentPlan: false,
                    duration: 'MONTH',
                },
            ],
            loadingPlan: null,
        };
    },

    computed: {
        ...mapGetters('membership', ['membershipPlan']),
    },

    methods: {
        ...mapActions('modalStore', ['closeModal', 'openModal']),

        handleStartTrial(planTitle) {
            this.loadingPlan = planTitle;
            console.log('planTitle', planTitle);
            if (planTitle !== 'Free') {
                this.createShopifySubscription(planTitle);
            } else {
                this.cancelShopifySubscription();
            }
        },

        setCurrentPlan() {
            this.pricingPlans = this.pricingPlans.map((plan) => ({
                ...plan,
                isCurrentPlan: plan.title.toUpperCase() === this.membershipPlan,
            }));
        },

        cancelShopifySubscription() {
            this.openModal({
                component: CancelSubscriptionShopify,
            });
        },

        async createShopifySubscription(planTitle) {
            const selectedPlan = this.pricingPlans.find((plan) => plan.title === planTitle);
            if (!selectedPlan) {
                console.error(`Plan with title ${planTitle} not found`);
                return;
            }

            const subscriptionData = {
                membership_subscription_plan: selectedPlan.title.toUpperCase(),
                membership_duration_type: selectedPlan.duration,
                return_url: window.location.origin + '/settings/billing',
            };

            try {
                const response = await shopifyBillingService.createShopifySubscription(subscriptionData);

                const confirmationUrl = response.data.confirmationUrl;
                window.open(confirmationUrl, '_blank');
            } catch (error) {
                console.error('Error creating Shopify subscription:', error);
            } finally {
                this.loadingPlan = null;
            }
        },
    },

    mounted() {
        this.setCurrentPlan();
    },
};
</script>

<template>
    <div class="subscription-pricing-section">
        <div class="subscription-pricing-section__header">
            <md-button @click="closeModal()" class="md-icon-button">
                <md-icon>close</md-icon>
            </md-button>
        </div>
        <md-content class="subscription-pricing-section__content">
            <h2 class="subscription-pricing-section__title md-display-1">Update Your Plan</h2>
            <div class="subscription-pricing-section__pricing-cards">
                <SubscriptionPricingCard
                    v-for="plan in pricingPlans"
                    :key="plan.title.toUpperCase()"
                    :title="plan.title"
                    :pricePerMonth="plan.pricePerMonth"
                    :features="plan.features"
                    :isPopular="plan.isPopular"
                    :isCurrentPlan="plan.isCurrentPlan"
                    :isLoading="loadingPlan === plan.title"
                    @start-trial="handleStartTrial(plan.title)"
                />
            </div>
        </md-content>
    </div>
</template>

<style lang="scss" scoped>
.subscription-pricing-section {
    overflow-y: auto; // Enable vertical scrolling

    &__header {
        display: flex;
        justify-content: flex-end;
        padding: 1rem 1rem 0 0;
    }

    &__content {
        padding: 0 1rem 1rem 1rem;
    }

    &__title {
        text-align: center;
        margin-bottom: 24px;
    }

    &__pricing-cards {
        display: flex;
        justify-content: center;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }
    }
}
</style>
