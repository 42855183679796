<template>
    <div class="playground__main-panel">
        <playground-chat />
    </div>
</template>

<script>
import PlaygroundChat from '@/components/Playground/PlaygroundChat.vue';
export default {
    name: 'Playground',
    components: {
        PlaygroundChat,
    },
    data() {
        return {
            // Your component data goes here
        };
    },
    methods: {
        // Your component methods go here
    },
    computed: {
        // Your computed properties go here
    },
    mounted() {
        // Lifecycle hook for when the component is mounted
    },
};
</script>

<style scoped lang="scss">
.playground__main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}
</style>
