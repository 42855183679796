<template>
    <div class="custom-main-panel">
        <div>
            <md-progress-bar v-if="isLoading" md-mode="indeterminate"></md-progress-bar>
        </div>
        <div class="md-layout custom-content-panel">
            <div v-if="isLoading" class="overlay md-layout-item md-size-100"></div>
            <div class="md-layout-item md-size-100">
                <div class="custom-action-save">
                    <p class="md-subheading">Training Model</p>
                    <createScenarioWizard @scenarioCreated="fetchTableData" />
                </div>
            </div>
            <div class="table-panel md-layout-item md-size-100">
                <md-card>
                    <md-card-content class="custom-loading-spinner" v-if="isLoading">
                        <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                    </md-card-content>

                    <md-card-content v-else>
                        <div v-if="!isEmpty">
                            <md-table
                                :value="queriedData"
                                :md-sort.sync="currentSort"
                                :md-sort-order.sync="currentSortOrder"
                                class="paginated-table"
                            >
                                <md-table-toolbar>
                                    <md-field>
                                        <label for="pages">Per page</label>
                                        <md-select v-model="pagination.perPage" name="pages">
                                            <md-option
                                                v-for="item in pagination.perPageOptions"
                                                :key="item"
                                                :label="item"
                                                :value="item"
                                            >
                                                {{ item }}
                                            </md-option>
                                        </md-select>
                                    </md-field>

                                    <md-field>
                                        <md-input
                                            type="search"
                                            class="mb-3"
                                            clearable
                                            style="width: 200px"
                                            placeholder="Search scenarios"
                                            v-model="searchQuery"
                                        >
                                        </md-input>
                                    </md-field>
                                </md-table-toolbar>

                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Context" md-sort-by="context">{{
                                        truncateText(item.context, 50)
                                    }}</md-table-cell>
                                    <md-table-cell md-label="Wrong Answer" md-sort-by="wrong_answer">{{
                                        item.wrong_answer ? truncateText(item.wrong_answer, 50) : 'None'
                                    }}</md-table-cell>
                                    <md-table-cell md-label="Good Answer" md-sort-by="good_answer">{{
                                        truncateText(item.good_answer, 50)
                                    }}</md-table-cell>
                                    <md-table-cell md-label="Creation" md-sort-by="date_created">{{
                                        formatDate(item.date_created)
                                    }}</md-table-cell>
                                    <md-table-cell class="custom-icons" md-label="">
                                        <md-button
                                            class="md-just-icon md-danger md-simple"
                                            @click.native="handleDelete(item)"
                                        >
                                            <md-icon>delete</md-icon>
                                        </md-button>
                                        <editScenarioWizard :scenario="item" @dialogClosed="fetchTableData" />
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>

                        <div v-else-if="isEmpty">
                            <md-empty-state
                                md-icon="question_answer"
                                md-label="No Scenarios Found"
                                md-description="Don't let your Scenarios table stay empty. Help improve Lixsa accuracy by detailing where it goes wrong and what the right response should be."
                            >
                            </md-empty-state>
                        </div>
                    </md-card-content>
                    <md-card-actions v-if="!isEmpty">
                        <pagination
                            class="pagination-no-border pagination-success"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="totalEntries"
                            :from="from + 1"
                            :to="to"
                        >
                        </pagination>
                    </md-card-actions>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
// Components
import { Pagination } from '@/components';
import createScenarioWizard from '@/components/Training/FineTuning/createScenarioWizard.vue';
import editScenarioWizard from '@/components/Training/FineTuning/editScenarioWizard.vue';

// Services
import fineTuningService from '@/services/fineTuning/fineTuningService.js';

// Third party
import Fuse from 'fuse.js';
import Swal from 'sweetalert2';

export default {
    name: 'FineTuning',
    components: {
        Pagination,
        createScenarioWizard,
        editScenarioWizard,
    },
    computed: {
        /***
         * Returns a page from the searched data or the whole data. Search is performed in the watch section.
         */
        queriedData() {
            let result = this.tableData;

            if (this.searchQuery != '' && this.searchedData.length > 0) {
                result = this.searchedData;
            }

            result = [...result].sort((a, b) => b.date_created - a.date_created); // Sort by date of creation

            return result.slice(0, this.numberOfElements);
        },
        to() {
            return Math.min(this.pagination.currentPage * this.pagination.perPage, this.pagination.total);
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
        },
    },
    data() {
        return {
            currentSort: 'date_created',
            currentSortOrder: 'desc',
            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [10, 20, 40, 80, 100, 200, 400, 1000],
                total: 0,
            },
            searchQuery: '',
            propsToSearch: ['context'],
            tableData: [], // Empty as data will be fetched from the API
            searchedData: [],
            fuseSearch: null,
            totalEntries: 0,
            totalPages: 0,
            numberOfElements: 0, // Actual number of elements per page that came from request.

            isLoading: false, //Controls loading spinner.
            isEmpty: true, // Controls empty state.
        };
    },
    methods: {
        /** UTILITY */

        truncateText(text, length) {
            if (text.length <= length) {
                return text;
            } else {
                return text.substring(0, length) + '...';
            }
        },
        formatDate(timestamp) {
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
            const date = new Date(timestamp * 1000); // convert to milliseconds
            return date.toLocaleDateString('en-US', options); // adjust 'en-US' as per your need
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },

        /** BUSINESS */

        handleDelete(item) {
            Swal.fire({
                title: 'Are you sure?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4caf50',
                confirmButtonText: 'Yes, delete it!',
                buttonsStyling: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteRow(item);
                    this.showSuccessToast('Scenario deleted');
                }
            });
        },
        deleteRow(item) {
            let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === item.id);
            if (indexToDelete >= 0) {
                this.tableData.splice(indexToDelete, 1);
                fineTuningService
                    .deleteFineTuningScenario(item.uuid)
                    .then(() => {
                        this.fetchTableData();
                    })
                    .catch((error) => {
                        console.error('An error occurred while deleting the item:', error);
                    });
            }
        },

        async fetchTableData() {
            try {
                this.isLoading = true;
                const response = await fineTuningService.fetchFineTuningScenarios(
                    this.pagination.currentPage - 1,
                    this.pagination.perPage,
                );
                if (response && response.data) {
                    this.tableData = response.data.content;
                    this.totalEntries = response.data.totalElements;
                    this.totalPages = response.data.totalPages;
                    this.numberOfElements = response.data.numberOfElements;
                    this.pagination.total = response.data.totalElements;
                    this.isEmpty = response.data.empty;
                    // Re-initialize fuse with the new data
                    this.fuseSearch = new Fuse(this.tableData, {
                        keys: ['context'],
                        threshold: 0.3,
                    });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.isLoading = false;
            }
        },
    },
    mounted() {
        // Initial data fetch
        this.fetchTableData();
    },
    watch: {
        /**
         * Searches through the table data by a given query.
         * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
         * @param value of the query
         */
        searchQuery(value) {
            let result = this.tableData;
            if (value !== '') {
                const searchResults = this.fuseSearch.search(this.searchQuery);
                result = searchResults.map((res) => res.item);
            }
            this.searchedData = result;
        },

        'pagination.currentPage': function () {
            // Fetch new page data when the current page changes
            this.fetchTableData();
        },
        'pagination.perPage': function () {
            // Fetch new data when the per page changes
            this.fetchTableData();
        },
    },
};
</script>

<style lang="scss" scoped>
.md-layout-item {
    padding-left: 0px;
    padding-right: 0px;
}

.md-card .md-card-actions {
    border: 0;
    margin-left: 20px;
    margin-right: 20px;
}

.buttons-panel {
    display: flex;
    align-items: center;
    height: fit-content;
}

.table-panel {
    display: flex;
    align-items: center;
    height: fit-content;
}

.custom-main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.custom-content-panel {
    margin-bottom: 40px;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 1278px) {
        padding-left: 0;
        padding-right: 0;
    }

    @media (max-width: 959px) {
        padding-left: 0;
        padding-right: 0;
    }

    .md-card {
        margin-top: 15px;
    }
}

.custom-url-field {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
    max-width: 100%;
}

.custom-check-icon {
    color: #4caf50;
}

.custom-error-icon {
    color: #f44336;
}

.custom-loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-icons {
    padding-right: 35px !important;

    .md-button {
        margin-right: 6px;
    }
}

.custom-action-save {
    @media (min-width: 599px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(238, 238, 238, 0.5);
    z-index: 10;
    cursor: not-allowed;
}
</style>
