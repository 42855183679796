<template>
    <div class="contact-form">
        <h2 class="step-title md-subheading">Contact Information</h2>
        <md-field>
            <label>Contact Name</label>
            <md-input v-model="contactName" required></md-input>
        </md-field>
        <md-button class="md-primary md-block" @click="emitSuccess" :disabled="!isFormValid">Create</md-button>
    </div>
</template>

<script>
export default {
    name: 'CreateRoomContactForm',
    props: {
        initialName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            contactName: this.initialName,
        };
    },
    computed: {
        isFormValid() {
            return this.contactName.trim() !== '';
        },
    },
    methods: {
        emitSuccess() {
            if (this.isFormValid) {
                this.$emit('step-completed', {
                    contactName: this.contactName,
                });
            }
        },
    },
    watch: {
        initialName(newValue) {
            this.contactName = newValue;
        },
    },
};
</script>

<style scoped>
.contact-form {
    display: flex;
    flex-direction: column;
}

.step-title {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 8px;
}

.md-field {
    margin-bottom: 8px;
}

.md-button {
    align-self: flex-end;
}
</style>
