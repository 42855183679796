<script>
import { mapActions } from 'vuex';
import SkillDetailsInfoCard from '@/components/Skills/SkillDetailsInfoCard.vue';
import SkillsService from '@/services/skills/SkillsService';
import AgentSettingsService from '@/services/account/agentSettingsService';
import SkillDetailsAboutCard from '@/components/Skills/SkillDetailsAboutCard.vue';
import Swal from 'sweetalert2';
import ChannelsWhatsappAboutCard from '@/components/Skills/Channels/ChannelsWhatsappAboutCard.vue';
import ChannelsWhatsappHowToSetupCard from './Channels/ChannelsWhatsappHowToSetupCard.vue';
import MainAppsShopifyAboutCard from '@/components/Skills/MainApps/MainAppsShopifyAboutCard.vue';
import communicationChannelsService from '@/services/account/communicationChannelsService';
import ConnectionDetails from '@/components/Skills/MainApps/MainAppsShopifyConnectionDetails.vue';

export default {
    name: 'SkillDetails',
    components: {
        ChannelsWhatsappAboutCard,
        ChannelsWhatsappHowToSetupCard,
        MainAppsShopifyAboutCard,
        SkillDetailsAboutCard,
        SkillDetailsInfoCard,
        ConnectionDetails,
    },
    data() {
        return {
            skill: {},
            isEnable: false,
            isLoadingSkillInfo: true,
            communicationChannel: {},
            currentSkillId: null,
        };
    },

    computed: {
        isWhatsappSkill() {
            return this.skill.uuid === '8c0f230d-cca0-4335-a359-d3fd4ab284cc';
        },
        isShopifySkill() {
            return this.skill.uuid === 'd74f8439-bda9-420a-8b20-cbb80e5f7510';
        },
    },

    watch: {
        '$route.params.skillId': {
            immediate: true,
            handler(newSkillId) {
                if (this.currentSkillId !== newSkillId) {
                    this.currentSkillId = newSkillId;
                    this.loadSkillData();
                }
            },
        },
    },

    methods: {
        ...mapActions('websocket', ['subscribe', 'unsubscribe']),
        ...mapActions('agentSettings', ['updateAgentSetting']),

        goBack() {
            this.$router.push('/skills/discovery');
        },

        async loadSkillData() {
            if (!this.currentSkillId) return;

            this.isLoadingSkillInfo = true;
            this.skill = {};
            this.isEnable = false;
            this.communicationChannel = {};

            try {
                await this.fetchSkillInformation(this.currentSkillId);
                await this.checkIfTheSkillIsEnabledForTheAccount();
                if (this.isWhatsappSkill) {
                    await this.getCommunicationChannel();
                    await this.updateAgentSetting();
                    this.subscribeToChannel();
                }
            } catch (error) {
                console.error('Error loading skill data:', error);
                this.showErrorToast('Error loading skill data');
            } finally {
                this.isLoadingSkillInfo = false;
            }
        },

        async fetchSkillInformation(skillId) {
            try {
                const response = await SkillsService.getSkill(skillId);
                this.skill = response.data;
            } catch (error) {
                console.error('Error fetching Skill details: ', error);
                throw error;
            }
        },

        async checkIfTheSkillIsEnabledForTheAccount() {
            try {
                const response = await AgentSettingsService.getAgentSettings();
                const skillsList = [...response.data.skills];
                this.isEnable = skillsList.some((skill) => skill.skill_id === this.skill.uuid);
            } catch (error) {
                console.error('Error checking account skills: ', error);
                throw error;
            }
        },

        async getCommunicationChannel() {
            try {
                const fetchCommunicationChannelsResponse =
                    await communicationChannelsService.fetchCommunicationChannels();
                this.communicationChannel = fetchCommunicationChannelsResponse.data.content[0];
            } catch (error) {
                console.error('Error getting communication channel: ', error);
                throw error;
            }
        },

        async handleEnableSkill(event = null) {
            try {
                const reqData = {
                    skill_id: this.skill.uuid,
                    agent_id: this.$store.state.user.user.account_id,
                    account_id: this.$store.state.user.user.account_id,
                };

                // Only add config_schema_values if event is not null
                if (event !== null) {
                    reqData.config_schema_values = { ...event };
                }

                await AgentSettingsService.addSkillToAgentSettings(reqData);
                await this.updateAgentSetting();
                if (this.isWhatsappSkill) {
                    await this.getCommunicationChannel();
                }
                this.isEnable = true;
                this.showSuccessToast('Skill activated correctly');
            } catch (error) {
                console.error('Error activating Skill: ', error);
                this.showErrorToast('Error activating skill');
            }
        },

        handleDisableSkill() {
            Swal.fire({
                title: 'Are you sure?',
                text: `This action will deactivate the skill and all its features.`,
                showCancelButton: true,
                confirmButtonColor: '#4A25E1',
                confirmButtonText: 'Yes, disable it!',
                buttonsStyling: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.disableSkill();
                }
            });
        },

        disableSkill() {
            this.removeAgentSettingsSkills();
        },

        async removeAgentSettingsSkills() {
            try {
                const reqData = {
                    skill_id: this.skill.uuid,
                    agent_id: this.$store.state.user.user.account_id,
                    account_id: this.$store.state.user.user.account_id,
                };
                await AgentSettingsService.removeSkillFromAgentSettings(this.skill.uuid, reqData);
                await this.updateAgentSetting();
                this.isEnable = false;
                this.showSuccessToast('Skill deactivated correctly');
            } catch (error) {
                console.error('Error deactivating Skill: ', error);
                this.showErrorToast('Error deactivating Skill');
            }
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },

        subscribeToChannel() {
            const topic = `/account/${this.$store.state.user.user.account_id}/communication-channel/${this.$store.state.user.user.account_id}`;
            this.subscribe({
                topic,
                callback: this.handleChannelEvent,
            });
        },

        handleChannelEvent(message) {
            if (!message || !message.data) return;

            switch (message.name) {
                case 'CommunicationChannelCreated':
                    this.handleEnableSkill();
                    break;
            }
        },
    },

    beforeDestroy() {
        if (this.isWhatsappSkill) {
            const topic = `/account/${this.$store.state.user.user.account_id}/communication-channel/${this.$store.state.user.user.account_id}`;
            this.unsubscribe({
                topic,
                callback: this.handleChannelEvent,
            });
        }
    },
};
</script>

<template>
    <div class="md-layout skills-details">
        <div class="md-layout-item md-size-100">
            <div class="skills-details__back-button" @click="goBack">
                <md-icon class="skills-details__back-button-icon">keyboard_backspace</md-icon>
                <span class="md-body-1">Back to skills</span>
            </div>
            <div v-if="isLoadingSkillInfo">
                <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
            </div>
            <div v-else>
                <skill-details-info-card
                    @skill-disabled="handleDisableSkill"
                    @skill-enabled="handleEnableSkill"
                    :skill="skill"
                    :is-enable="isEnable"
                />
                <channels-whatsapp-about-card
                    :communication-channel="communicationChannel"
                    v-if="isWhatsappSkill && isEnable"
                ></channels-whatsapp-about-card>
                <channels-whatsapp-how-to-setup-card
                    :communication-channel="communicationChannel"
                    v-if="isWhatsappSkill && !isEnable"
                ></channels-whatsapp-how-to-setup-card>
                <main-apps-shopify-about-card v-if="isShopifySkill && !isEnable"></main-apps-shopify-about-card>
                <connection-details v-if="isShopifySkill && isEnable"></connection-details>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@/assets/scss/md/variables';

.skills-details {
    padding: 0 15px;

    &__back-button {
        color: $gray !important;
        margin-bottom: 15px;
        width: fit-content;

        &:hover {
            cursor: pointer;
            color: $gray-darker !important;

            i {
                color: $gray-darker !important;
            }
        }
    }

    &__back-button-icon {
        color: $gray !important;
        margin-right: 8px;
    }
}

.md-layout-item {
    @media (max-width: 959px) {
        padding: 0;
    }
}
</style>
