import BaseDto from '../../_common/BaseDto';

class SubscriptionResponseDataBuilder {
    constructor() {
        this.id = undefined;
        this.productId = undefined;
        this.productName = undefined;
        this.trialEnd = undefined;
        this.status = undefined;
        this.cancelAtPeriodEnd = undefined;
        this.cancelAt = undefined;
    }

    withId(id) {
        this.id = id;
        return this;
    }

    withProductId(productId) {
        this.productId = productId;
        return this;
    }

    withProductName(productName) {
        this.productName = productName;
        return this;
    }

    withTrialEnd(trialEnd) {
        this.trialEnd = trialEnd;
        return this;
    }

    withStatus(status) {
        this.status = status;
        return this;
    }

    withCancelAtPeriodEnd(cancelAtPeriodEnd) {
        this.cancelAtPeriodEnd = cancelAtPeriodEnd;
        return this;
    }

    withCancelAt(cancelAt) {
        this.cancelAt = cancelAt;
        return this;
    }

    build() {
        return new SubscriptionResponseData(this);
    }
}

class SubscriptionResponseData extends BaseDto {
    constructor(builder) {
        super();
        this.id = builder.id;
        this.productId = builder.productId;
        this.productName = builder.productName;
        this.trialEnd = builder.trialEnd;
        this.status = builder.status;
        this.cancelAtPeriodEnd = builder.cancelAtPeriodEnd;
        this.cancelAt = builder.cancelAt;
    }

    static get Builder() {
        return SubscriptionResponseDataBuilder;
    }
}

export default SubscriptionResponseData;
