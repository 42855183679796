<template>
    <div class="communication-channel-selector">
        <h2 class="step-title md-subheading">Select Communication Channel</h2>
        <md-field>
            <label for="communication-channel">Communication Channel</label>
            <md-select
                v-model="selectedChannel"
                name="communication-channel"
                id="communication-channel"
                @md-selected="emitSuccess"
            >
                <md-option value="whatsapp">WhatsApp</md-option>
                <!-- Add more options here as they become available -->
            </md-select>
        </md-field>
    </div>
</template>

<script>
export default {
    name: 'CreateRoomCommunicationChannelSelector',
    data() {
        return {
            selectedChannel: 'whatsapp',
        };
    },
    created() {
        this.emitSuccess();
    },
    methods: {
        emitSuccess() {
            this.$emit('step-completed', this.selectedChannel);
        },
    },
};
</script>

<style scoped>
.communication-channel-selector {
    display: flex;
    flex-direction: column;
}

.step-title {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 8px;
}

.md-field {
    margin-bottom: 0;
}
</style>
