<script>
import CodeSnippet from '@/components/codeSnippet.vue';
import ChannelsWhatsappEditProfileBanner from '@/components/Skills/Channels/ChannelsWhatsappEditProfileBanner.vue';
import shopifyService from '../../../services/shopify/shopifyService';
import { mapGetters } from 'vuex';
export default {
    name: 'ChannelsWhatsappAboutCard',
    props: ['communicationChannel'],
    data() {
        return {
            selectedThemeId: 'current',
            shopifyThemes: ['current'],
            chatBubbleInstalled: null,
        };
    },
    components: { ChannelsWhatsappEditProfileBanner, CodeSnippet },
    computed: {
        ...mapGetters('agentSettings', ['getSkillByInternalName']),

        getWhatsappSkill() {
            return this.getSkillByInternalName('whatsapp');
        },

        whatsappScript() {
            const phoneNumber = this.getWhatsappSkill?.config_values
                ? this.getWhatsappSkill.config_values.phoneNumber || '{Your-Phone-Number}'
                : '{Your-Phone-Number}';
            const defaultMessage = '{Your-Default-Message}';
            return `&lt;script src="https://wsp.lixsa.ai/whatsapp-widget.js" phone="${phoneNumber}" message="${defaultMessage}"&gt;&lt;/script&gt;`;
        },
    },
    methods: {
        addWhatsappToShopify() {
            const shopifySKillId = 'd74f8439-bda9-420a-8b20-cbb80e5f7510';
            const activeSkills = this.$store.getters['agentSettings/agentSettings'].skills;
            const shopifySkill = activeSkills.find((skill) => skill.skill_id === shopifySKillId);
            const myShopifyDomain = shopifySkill.config_values?.myShopifyDomain;
            const chatBubbleBlockId = 'fd9562b5-04d3-42f1-8257-0bdb4df0e3a3/chat-bubble';

            window.open(
                `https://${myShopifyDomain}/admin/themes/${this.selectedThemeId}/editor?template=index&addAppBlockId=${chatBubbleBlockId}&target=sectionGroup:header`,
            );
        },
        hasShopifySkill() {
            const shopifySKillId = 'd74f8439-bda9-420a-8b20-cbb80e5f7510';

            const activeSkills = this.$store.getters['agentSettings/agentSettings'].skills;

            const hasShopifySkill = activeSkills.some((skill) => skill.skill_id === shopifySKillId);

            return hasShopifySkill;
        },
        async getShopifyThemes() {
            try {
                const response = await shopifyService.getShopThemes();

                this.shopifyThemes = response.data;
                this.selectedThemeId = this.shopifyThemes.find((theme) => theme.role === 'main').id;

                this.shopifyThemes.sort((a, b) => {
                    if (a.role === 'main') return -1;
                    if (b.role === 'main') return 1;
                    return 0;
                });
            } catch (error) {
                console.error('Error fetching Shopify themes:', error);
            }
        },
        async isChatBubbleInstalled() {
            try {
                const response = await shopifyService.isChatBubbleInstalled(this.selectedThemeId);
                this.chatBubbleInstalled = response.data;
            } catch (error) {
                console.error('Error checking if chat bubble is installed:', error);
                this.chatBubbleInstalled = null;
            }
        },
    },
    watch: {
        selectedThemeId() {
            this.isChatBubbleInstalled();
        },
    },
    mounted() {
        if (this.hasShopifySkill()) {
            this.getShopifyThemes();
        }
    },
};
</script>

<template>
    <div>
        <ChannelsWhatsappEditProfileBanner :communication-channel="communicationChannel" />
        <md-card>
            <md-card-content class="channels-whatsapp-about-card">
                <div class="md-title"><strong> Adding a Floating WhatsApp Chat Bubble to Your Website </strong></div>

                <div class="md-body-1">
                    Add a floating chat bubble to your website that allows users to initiate a conversation with your
                    WhatsApp Business account. This feature enhances customer interaction by providing an easy and
                    direct way for visitors to reach out to you with inquiries, support requests, or feedback.
                </div>

                <div class="md-subheading"><strong> What You Need </strong></div>
                <div class="md-body-1">
                    <div>To add the floating WhatsApp chat bubble to your website, you will need:</div>
                    <ul>
                        <li>Your WhatsApp Business account phone number.</li>
                        <li>A default message that will appear in the text box when users click the chat bubble.</li>
                    </ul>
                </div>

                <div class="add-to-shopify-container" v-if="hasShopifySkill()">
                    <div class="md-subheading shopify-title"><strong> Setup in Shopify </strong></div>

                    <div class="add-to-shopify-container_content">
                        <div class="md-body-1">
                            <div>Select the theme to add the WhatsApp bubble</div>
                        </div>
                        <md-field class="theme-selector">
                            <label for="selectedThemeId">Theme</label>
                            <md-select v-model="selectedThemeId" name="selectedThemeId" id="selectedThemeId">
                                <md-option v-for="theme in shopifyThemes" :key="theme.id" :value="theme.id">
                                    {{ theme.name }} {{ theme.role === 'main' ? '(current)' : '' }}
                                </md-option>
                            </md-select>
                        </md-field>
                        <span class="md-body-1">When activated, the chat bubble will appear in all pages</span>
                        <div class="button-wrapper">
                            <md-button
                                class="md-sm md-primary add-to-shopify-button"
                                @click="addWhatsappToShopify"
                                :disabled="chatBubbleInstalled"
                            >
                                <img
                                    src="@/assets/icons/shopifyIcon_white.svg"
                                    alt="Shopify Icon"
                                    class="shopify-icon"
                                /><span class="add-shopify-text">Add to Shopify Theme</span>
                            </md-button>
                            <span v-if="chatBubbleInstalled"
                                >The selected theme already has the Whatsapp bubble activated</span
                            >
                        </div>
                        <div class="md-body-1">Or add the script manually to your website:</div>
                    </div>
                </div>
                <div v-else>
                    <div class="md-subheading"><strong> The script</strong></div>
                </div>
                <code-snippet :code="whatsappScript" language="language-html" />
            </md-card-content>
        </md-card>
    </div>
</template>

<style scoped lang="scss">
.channels-whatsapp-about-card {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.shopify-title {
    margin-bottom: 1rem;
}

.add-to-shopify-container_content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.theme-selector {
    width: 250px;
}

.button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.add-to-shopify-button {
    width: 230px;
}

.shopify-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.add-shopify-text {
    font-size: 16px;
}
</style>
