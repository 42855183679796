<script>
export default {
    name: 'RegisterForm',
    emits: ['perform-register'],
    props: ['loading'],
    data() {
        return {
            error: '',
            inputs: [
                {
                    name: 'Name',
                    nameAttr: 'firstname',
                    type: 'text',
                    hasError: false,
                    errorMessage: '',
                    value: null,
                },

                {
                    name: 'Email',
                    nameAttr: 'email',
                    type: 'email',
                    hasError: false,
                    errorMessage: '',
                    value: null,
                    formatValue: (value) => value.replace(/\s/g, '+'),
                },

                {
                    name: 'Password',
                    nameAttr: 'password',
                    type: 'password',
                    hasError: false,
                    errorMessage: '',
                    value: null,
                },
            ],
        };
    },
    computed: {
        isFormValid() {
            return this.inputs.every((input) => input.hasError === false);
        },
    },
    methods: {
        messageClass(hasError) {
            return {
                'md-invalid': hasError,
            };
        },

        register() {
            this.inputs.forEach((input) => {
                this.handleValidation(input);
            });

            if (this.isFormValid) {
                this.$emit('perform-register', this.inputs);
            }
        },

        handleValidation(item) {
            switch (item.nameAttr) {
                case 'firstname':
                    this.validateNameInput(item);
                    break;
                case 'email':
                    this.validateEmailInput(item);
                    break;
                case 'password':
                    this.validatePasswordInput(item);
                    break;
            }
        },

        validateNameInput(item) {
            if (!item.value) {
                item.hasError = true;
                item.errorMessage = 'Value must not be empty';
                return;
            }

            item.hasError = false;
            item.errorMessage = '';
        },

        validateEmailInput(item) {
            if (!item.value) {
                item.hasError = true;
                item.errorMessage = 'Value must not be empty';
                return;
            }

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(item.value)) {
                item.hasError = true;
                item.errorMessage = 'Invalid email format';
                return;
            }

            item.hasError = false;
            item.errorMessage = '';
        },

        validatePasswordInput(item) {
            if (!item.value) {
                item.hasError = true;
                item.errorMessage = 'Value must not be empty';
                return;
            }

            const passwordRegex = /^(?=.*[0-9a-zA-Z]).{6,}$/;
            if (!passwordRegex.test(item.value)) {
                item.hasError = true;
                item.errorMessage = 'Password must be at least 6 characters and include alphanumeric characters';
                return;
            }

            item.hasError = false;
            item.errorMessage = '';
        },
    },
};
</script>

<template>
    <div class="register-form">
        <div class="register-form__inputs-section md-layout-item md-size-100">
            <md-field v-for="item in inputs" :key="item.name" :class="messageClass(item.hasError)">
                <label
                    ><strong>{{ item.name }}</strong></label
                >
                <md-input
                    @focusout="handleValidation(item)"
                    v-model="item.value"
                    :type="item.type"
                    @keyup.enter="register"
                ></md-input>
                <span class="md-error register-form__text--left">{{ item.errorMessage }}</span>
            </md-field>
        </div>

        <div class="md-layout-item md-size-100">
            <md-button class="md-primary md-block" v-on:click="register">
                <md-progress-spinner
                    v-if="loading"
                    :md-diameter="12"
                    :md-stroke="2"
                    class="md-white"
                    md-mode="indeterminate"
                ></md-progress-spinner>
                <span class="md-body-2">Sign Up for FREE</span>
            </md-button>
        </div>
    </div>
</template>

<style scoped lang="scss">
.register-form {
    width: 100%;
    height: 100%;
}

.register-form__inputs-section {
    margin-top: 15px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.register-form__text--left {
    text-align: start;
}

.md-progress-spinner {
    margin-right: 1rem;
}
</style>
