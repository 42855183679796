<template>
    <div class="topics-table">
        <md-card>
            <md-card-content>
                <md-table
                    :value="queriedData"
                    :md-sort.sync="currentSort"
                    :md-sort-order.sync="currentSortOrder"
                    class="paginated-table table-hover"
                >
                    <md-table-toolbar>
                        <md-field>
                            <label for="pages">Per page</label>
                            <md-select v-model="pagination.perPage" name="pages">
                                <md-option
                                    v-for="item in pagination.perPageOptions"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                >
                                    {{ item }}
                                </md-option>
                            </md-select>
                        </md-field>

                        <md-field>
                            <md-input
                                type="search"
                                class="mb-3"
                                clearable
                                style="width: 200px"
                                placeholder="Search records"
                                v-model="searchQuery"
                            >
                            </md-input>
                        </md-field>
                    </md-table-toolbar>

                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Open Topics" md-sort-by="name">{{ item.name }}</md-table-cell>
                        <md-table-cell md-label="Category" md-sort-by="category">
                            <md-chip :class="getChipColor(item.category)">
                                <span> {{ item.category }} </span>
                            </md-chip>
                        </md-table-cell>
                        <md-table-cell md-label="Conversations" md-sort-by="conversations">{{
                            item.conversations
                        }}</md-table-cell>
                        <md-table-cell md-label="AI resolved">{{ item.ai_resolved }}</md-table-cell>
                    </md-table-row>
                </md-table>
            </md-card-content>
            <md-card-actions>
                <pagination
                    class="pagination-no-border pagination-success"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="total"
                    :from="from + 1"
                    :to="to"
                >
                </pagination>
            </md-card-actions>
        </md-card>
    </div>
</template>

<script>
import { Pagination } from '@/components';
import Fuse from 'fuse.js';

export default {
    components: {
        Pagination,
    },
    props: {
        topicsData: {
            type: Array,
            default: [],
        },
    },
    computed: {
        /***
         * Returns a page from the searched data or the whole data. Search is performed in the watch section below
         */
        queriedData() {
            let result = this.tableData;
            if (this.searchedData.length > 0) {
                result = this.searchedData;
            }

            return result.slice(this.from, this.to);
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0 ? this.searchedData.length : this.tableData.length;
        },
    },
    data() {
        return {
            currentSort: 'conversations',
            currentSortOrder: 'desc',
            pagination: {
                perPage: 5,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0,
            },
            searchQuery: '',
            propsToSearch: ['name'],
            tableData: [],
            searchedData: [],
            fuseSearch: null,
        };
    },
    methods: {
        getChipColor(category) {
            switch (category) {
                case 'Question':
                    return 'question-chip';
                case 'Feedback':
                    return 'feedback-chip';
                case 'Bug':
                    return 'issue-chip';
                default:
                    break;
            }
        },
        customSort(value) {
            return value.sort((a, b) => {
                const sortBy = this.currentSort;
                if (this.currentSortOrder === 'desc') {
                    return a[sortBy].localeCompare(b[sortBy]);
                }
                return b[sortBy].localeCompare(a[sortBy]);
            });
        },
    },
    mounted() {
        // Fuse search initialization.
        this.fuseSearch = new Fuse(this.tableData, {
            keys: ['name'],
            threshold: 0.3,
        });
    },
    created() {
        this.tableData = [...this.topicsData];
    },
    watch: {
        /**
         * Searches through the table data by a given query.
         * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
         * @param value of the query
         */
        searchQuery(value) {
            let result = this.tableData;
            if (value !== '') {
                const searchResults = this.fuseSearch.search(this.searchQuery);
                result = searchResults.map((res) => res.item);
            }
            this.searchedData = result;
        },

        topicsData: {
            deep: true,
            handler(newValue) {
                this.tableData = [...newValue];
            },
        },
    },
};
</script>
