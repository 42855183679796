<script>
import { mapGetters, mapActions } from 'vuex';
import SubscriptionFreePlan from '@/components/Settings/Subscription/SubscriptionFreePlan.vue';
import SubscriptionPaidPlan from '@/components/Settings/Subscription/SubscriptionPaidPlan.vue';
import SubscriptionFeaturesTable from '@/components/Settings/Subscription/SubscriptionFeaturesTable.vue';
import stripeApiRepository from '@/services/stripe/StripeApiRepository';

export default {
    name: 'Subscription',
    components: { SubscriptionFeaturesTable, SubscriptionPaidPlan, SubscriptionFreePlan },
    data() {
        return {
            isLoading: false,
            seeMore: true,
        };
    },
    computed: {
        ...mapGetters('membership', ['ifSubscribed']),
        ...mapGetters('user', ['isShopifyBillingClient']),
    },
    methods: {
        ...mapActions('membership', ['getAccountMembership', 'getAccountMembershipByExternalBillingId']),

        async fetchSubscriptionData() {
            await this.getAccountMembership();
            if (!this.isShopifyBillingClient) {
                this.$store.dispatch(
                    'membership/fetchStripeSubscription',
                    this.$store.getters['membership/membershipId'],
                );
            }
        },

        async fetchCheckoutSession(sessionId) {
            try {
                const checkoutSession = await stripeApiRepository.getCheckoutSession(sessionId);

                if (checkoutSession.payment_status === 'paid') {
                    this.showSuccessToast('Subscription completed!');
                    await this.getAccountMembership();
                    this.$store.dispatch(
                        'membership/fetchStripeSubscription',
                        this.$store.getters['membership/membershipId'],
                    );
                    // TODO: Create a loading view.
                }
            } catch (error) {
                console.error('Error fetching checkout session: ' + error);
            }
        },

        async checkShopifyBillingSessionIsComplete() {
            try {
                await this.getAccountMembership();
                this.showSuccessToast('Subscription completed!');
            } catch (error) {
                console.error('Error checking if shopify billing session is complete: ', error);
                this.showErrorToast('Error checking if shopify billing session is complete');
            }
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },

    mounted() {
        if (this.ifSubscribed) {
            this.fetchSubscriptionData();
        }

        if (this.$route.query.session_id) {
            this.fetchCheckoutSession(this.$route.query.session_id);
        }

        if (this.$route.query.charge_id) {
            this.checkShopifyBillingSessionIsComplete();
        }
    },
};
</script>

<template>
    <div class="subscription__main-panel subscription">
        <div>
            <md-progress-bar v-if="isLoading" md-mode="indeterminate"></md-progress-bar>
        </div>
        <div class="md-layout subscription__content-panel">
            <div v-if="isLoading" class="subscription__overlay md-layout-item md-size-100"></div>

            <subscription-paid-plan v-if="ifSubscribed" />

            <subscription-free-plan v-else />
            <div class="md-layout-item md-size-100 subscription__see-more-button-section">
                <md-button v-if="!ifSubscribed" @click="seeMore = !seeMore" class="md-primary md-simple">
                    More Details
                    <i v-if="!seeMore" class="material-symbols-outlined"> keyboard_arrow_up </i>
                    <i v-else class="material-symbols-outlined"> keyboard_arrow_down </i>
                </md-button>
            </div>
            <subscription-features-table v-if="!ifSubscribed && seeMore" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.subscription__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(238, 238, 238, 0.5);
    z-index: 10;
    cursor: not-allowed;
}

.subscription__main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.subscription__content-panel {
    margin-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 1278px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 959px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.subscription__see-more-button-section {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
