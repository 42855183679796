<template>
    <ul class="nav nav-mobile-menu">
        <li>
            <a v-on:click="logout" data-toggle="dropdown" class="dropdown-toggle">
                <i style="color: #a9afbb !important" class="material-symbols-outlined">logout</i>
                <p>Logout</p>
            </a>
        </li>
    </ul>
</template>
<script>
import { getAuth, signOut } from 'firebase/auth';
export default {
    data() {
        return {
            search: null,
            selectedEmployee: null,
            employees: [
                'Jim Halpert',
                'Dwight Schrute',
                'Michael Scott',
                'Pam Beesly',
                'Angela Martin',
                'Kelly Kapoor',
                'Ryan Howard',
                'Kevin Malone',
            ],
        };
    },
    methods: {
        logout() {
            this.$store
                .dispatch('user/logout')
                .then(() => {
                    if (this.$route.path !== '/') {
                        this.$router.push({ name: 'Login', query: { logout: 'true' } });
                    }
                })
                .catch((error) => {
                    this.error = error.message;
                });
        },
    },
};
</script>
