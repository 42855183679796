var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout text-center"},[_c('div',{staticClass:"custom-signup-panel md-layout md-layout-item md-size-70 md-xsmall-size-100"},[_vm._m(0),(_vm.error !== '')?_c('div',{staticClass:"md-layout-item md-size-100"},[_c('AuthErrorPanel',{attrs:{"error-message":_vm.error},on:{"close-panel":_vm.clearError}})],1):_vm._e(),_c('div',{staticClass:"md-layout-item md-size-100 register__social-section"},[_c('RegisterSocialButtonGoogle',{attrs:{"query-params":_vm.queryParams},on:{"onError":(args) => {
                        _vm.handleError(args);
                    }}}),_c('RegisterSocialButtonFacebook',{attrs:{"query-params":_vm.queryParams},on:{"onError":(args) => {
                        _vm.handleError(args);
                    }}})],1),_vm._m(1),_c('RegisterForm',{attrs:{"loading":_vm.loading},on:{"perform-register":(args) => _vm.register(args)}})],1),_vm._m(2),_c('div',{staticClass:"md-layout-item md-size-70 md-xsmall-size-100"},[_c('div',{staticClass:"md-body-1 register__text--left"},[_vm._v(" Already have and account? "),_c('router-link',{attrs:{"to":"/"}},[_vm._v("Click here to sign in")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout-item md-size-100"},[_c('div',{staticClass:"md-display-1 register__text--left register__title"},[_c('strong',[_vm._v(" Sign up ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout-item md-size-100 register__text--left"},[_c('p',{staticClass:"md-body-1"},[_vm._v("Or sign up with your email:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout-item md-size-70 md-xsmall-size-100"},[_c('p',{staticClass:"md-body-1 register__text--left"},[_vm._v(" By creating your account, you agree to the "),_c('a',{attrs:{"href":"https://www.lixsa.ai/terms"}},[_vm._v(" Terms of Service ")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://www.lixsa.ai/privacy"}},[_vm._v("Privacy Policy")]),_vm._v(". ")])])
}]

export { render, staticRenderFns }