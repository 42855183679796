<template>
    <div>
        <md-button :disabled="isLoading" @click="launchWhatsAppSignup" class="md-primary">
            <md-progress-spinner
                v-if="isLoading"
                :md-diameter="12"
                :md-stroke="2"
                class="md-white"
                md-mode="indeterminate"
            ></md-progress-spinner>
            <span v-else>Connect</span>
        </md-button>
    </div>
</template>

<script>
import facebookService from '@/services/facebook/facebookService';
import communicationChannelsService from '@/services/account/communicationChannelsService';
import Swal from 'sweetalert2';

function generatePin() {
    return Math.floor(100000 + Math.random() * 900000).toString();
}

export default {
    name: 'ChannelsFacebookLoginButton',

    emits: ['account-connected'],

    data() {
        return {
            code: null,
            phone_number_id: null,
            waba_id: null,
            isLoading: false,
        };
    },

    watch: {
        code(newVal) {
            this.handleCodeChange(newVal);
        },
    },

    computed: {
        processIsCompleted() {
            return this.code && this.phone_number_id && this.waba_id;
        },
    },

    methods: {
        loadFacebookSDK() {
            window.fbAsyncInit = function () {
                FB.init({
                    appId: '2382869741901324', // Facebook App ID
                    cookie: true, // enable cookies
                    xfbml: true, // parse social plugins on this page
                    version: 'v19.0', //Graph API version
                });
            };

            // Load the JavaScript SDK asynchronously
            (function (d, s, id) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk.js';
                fjs.parentNode.insertBefore(js, fjs);
            })(document, 'script', 'facebook-jssdk');
        },

        launchWhatsAppSignup() {
            // Conversion tracking code
            //fbq && fbq('trackCustom', 'WhatsAppOnboardingStart', {appId: 'your-facebook-app-id', feature: 'whatsapp_embedded_signup'});

            this.isLoading = true;

            // Launch Facebook login
            FB.login(
                (response) => {
                    if (response.authResponse) {
                        this.code = response.authResponse.code;
                        // The returned code must be transmitted to your backend,
                        // which will perform a server-to-server call from there to our servers for an access token
                    } else {
                        this.isLoading = false;
                        console.log('User cancelled login or did not fully authorize.');
                    }
                },
                {
                    config_id: '451743360555763', // configuration ID goes here
                    response_type: 'code', // must be set to 'code' for System User access token
                    override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
                    extras: {
                        feature: 'whatsapp_embedded_signup',
                        sessionInfoVersion: 2, //  Receive Session Logging Info
                    },
                },
            );

            window.addEventListener('message', this.sessionInfoListener);
        },

        sessionInfoListener(event) {
            if (event.origin !== 'https://www.facebook.com' && event.origin !== 'https://web.facebook.com') {
                this.isLoading = false;
                return;
            }

            try {
                const data = JSON.parse(event.data);
                if (data.type === 'WA_EMBEDDED_SIGNUP') {
                    // if user finishes the Embedded Signup flow
                    if (data.event === 'FINISH') {
                        const { phone_number_id, waba_id } = data.data;
                        this.phone_number_id = phone_number_id;
                        this.waba_id = waba_id;
                        window.removeEventListener('message', this.sessionInfoListener);
                    }
                    // if user cancels the Embedded Signup flow
                    else {
                        const { current_step } = data.data;
                        console.log('Process cancelled, last step: ', current_step);
                    }
                }
            } catch {
                // Don’t parse info that’s not a JSON
                console.log('Non JSON Response', event.data);
            } finally {
                this.isLoading = false;
            }
        },

        async handleCodeChange(code) {
            if (!this.processIsCompleted) {
                return;
            }
            try {
                const access_token = await this.obtainFacebookToken(code);
                await this.registerWhatsappAccount(access_token);
                await this.subscribeWhatsappAccountToWebhooks(access_token);
                await this.createWhatsAppChannel(access_token, this.phone_number_id, this.waba_id);
            } catch (e) {
                console.error('Error creating communication channel: ', e);
            }
        },

        async subscribeWhatsappAccountToWebhooks(access_token) {
            try {
                await facebookService.subscribeAppToWebhooks(access_token, this.waba_id);
                // console.log('App subscribed to webhooks');
            } catch (e) {
                console.error('Error subscribing account to webhooks');
            }
        },

        async registerWhatsappAccount(access_token) {
            try {
                const pin = generatePin();
                const registerWhatsAppResponse = await facebookService.registerWhatsApp(
                    'whatsapp',
                    pin,
                    access_token,
                    this.phone_number_id,
                );
                // console.log('Whatsapp register completed: ', registerWhatsAppResponse);
                this.showAlert(
                    '2FA Pin',
                    `Your new PIN for two-step verification is: ${pin}, keep it in a safe place.`,
                );
            } catch (e) {
                console.error('Error registering account: ', e);
            }
        },

        async obtainFacebookToken(code) {
            try {
                const getFacebookAccessTokenResponse = await facebookService.getFacebookAccessToken(code);
                const extendDurationOfAccessTokenResponse = await facebookService.extendDurationOfAccessToken(
                    getFacebookAccessTokenResponse.data.access_token,
                );
                // console.log('access token obtained:');
                // console.log(extendDurationOfAccessTokenResponse.data.access_token);
                return extendDurationOfAccessTokenResponse.data.access_token;
            } catch (e) {
                console.error('Error obtainFacebookToken: ', e);
            }
        },

        async createWhatsAppChannel(access_token, phone_number_id, waba_id) {
            try {
                const reqData = {
                    access_token,
                    phone_number_id: phone_number_id,
                    business_account_id: waba_id,
                    channel_type: 'WHATSAPP',
                };
                await communicationChannelsService.createCommunicationChannel(reqData);
                this.showSuccessToast('Channel created successfully.');
                this.$emit('account-connected');
            } catch (e) {
                console.error('Error createWhatsAppChannel: ', e);
                this.showErrorToast('Error creating WhatsApp channel');
            }
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },

        showAlert(title, msg) {
            Swal.fire({
                title: title,
                text: msg,
                confirmButtonColor: '#4A25E1',
                confirmButtonText: 'Go it!',
                buttonsStyling: true,
            });
        },
    },
    mounted() {
        this.loadFacebookSDK();
    },
    beforeUnmount() {
        window.removeEventListener('message', this.sessionInfoListener);
        window.removeEventListener('beforeunload', this.handleWindowClose);
    },
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
