<script>
import SubscriptionPricingCard from './SubscriptionPricingCard.vue';
import stripeApiRepository from '@/services/stripe/StripeApiRepository';
import SubscriptionPaymentLinkRequest from '@/services/stripe/Dto/SubscriptionPaymentLinkRequest';
import { mapGetters } from 'vuex';

export default {
    name: 'StripePricingSection',
    components: {
        SubscriptionPricingCard,
    },
    data() {
        return {
            pricingPlans: [
                {
                    title: 'Free',
                    pricePerMonth: 0,
                    features: [
                        '30 conversations',
                        'FAQ Support Business Knowledge',
                        'Voice Recognition',
                        'API Integration',
                    ],
                    isPopular: false,
                    isCurrentPlan: true,
                    duration: 'MONTH',
                },
                {
                    title: 'Startup',
                    pricePerMonth: 150,
                    features: [
                        '7 Days Free Trial',
                        '250 conversations',
                        'FAQ Support Business Knowledge',
                        'Voice Recognition',
                        'API Integration',
                    ],
                    isPopular: false,
                    isCurrentPlan: false,
                    duration: 'MONTH',
                },
                {
                    title: 'Scaling',
                    pricePerMonth: 250,
                    features: [
                        '7 Days Free Trial',
                        '500 conversations',
                        'All features in Start Up Plan',
                        'CSAT Score Advance',
                        'Multi Language',
                        'Sales Agent',
                        '5 members',
                    ],
                    isPopular: true,
                    isCurrentPlan: false,
                    duration: 'MONTH',
                },
                {
                    title: 'Pro',
                    pricePerMonth: 550,
                    features: [
                        '7 Days Free Trial',
                        '1200 conversations',
                        'All features in Scaling Plan',
                        'CSAT Real Time',
                        'Simultaneous Answers Unlimited',
                        'Sales Agent',
                        '10 members',
                    ],
                    isPopular: false,
                    isCurrentPlan: false,
                    duration: 'MONTH',
                },
            ],
            loadingPlan: null,
        };
    },

    computed: {
        ...mapGetters('user', ['toltReferral']),
    },

    methods: {
        handleStartTrial(planTitle) {
            this.loadingPlan = planTitle;
            this.createStripeSubscription(planTitle);
        },

        async createStripeSubscription(planTitle) {
            const selectedPlan = this.pricingPlans.find((plan) => plan.title === planTitle);
            if (!selectedPlan) {
                console.error(`Plan with title ${planTitle} not found`);
                return;
            }

            const subscriptionPaymentLinkRequest = new SubscriptionPaymentLinkRequest.Builder()
                .withToltioReferral(this.toltReferral)
                .withSubscriptionPlan(selectedPlan.title.toUpperCase())
                .withSuccessUrl(window.location.origin + '/settings/billing')
                .withCancelUrl(window.location.origin + '/settings/billing')
                .build();

            try {
                const confirmationUrl = await stripeApiRepository.createSubscriptionPaymentLink(
                    this.$store.getters['membership/membershipId'],
                    subscriptionPaymentLinkRequest,
                );

                window.open(confirmationUrl, '_blank');
            } catch (error) {
                console.error('Error creating Stripe subscription:', error);
            } finally {
                this.loadingPlan = null;
            }
        },
    },
};
</script>

<template>
    <div class="subscription-pricing-section">
        <!-- <h2>Choose Your Plan</h2> -->
        <div class="pricing-cards">
            <SubscriptionPricingCard
                v-for="plan in pricingPlans"
                :key="plan.title.toUpperCase()"
                :title="plan.title"
                :pricePerMonth="plan.pricePerMonth"
                :features="plan.features"
                :isPopular="plan.isPopular"
                :isCurrentPlan="plan.isCurrentPlan"
                :isLoading="loadingPlan === plan.title"
                @start-trial="handleStartTrial(plan.title)"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.subscription-pricing-section {
    // padding: 32px;

    h2 {
        text-align: center;
        margin-bottom: 24px;
    }

    .pricing-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}
</style>
