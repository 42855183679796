<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'LoginSocialButtonFacebook',
    emits: ['on-error'],
    props: ['queryParams'],
    computed: {
        ...mapGetters('user', ['isShopifyBillingClient']),
    },
    methods: {
        ...mapActions('user', ['actionGetUser', 'actionGetAccount', 'actionSignUpWithFacebook']),
        ...mapActions('membership', ['getAccountMembership', 'subscribeToMembershipUpdates']),
        ...mapActions('agentSettings', ['updateAgentSetting']),

        logout() {
            this.$store
                .dispatch('user/logout')
                .then(() => {
                    if (this.$route.path !== '/') {
                        this.$router.push({ name: 'Login', query: { logout: 'true' } });
                    }
                })
                .catch((error) => {
                    this.error = error.message;
                });
        },

        async loginWithFacebook() {
            this.error = '';
            this.loading = true;

            try {
                const signupResponse = await this.actionSignUpWithFacebook();

                if (!signupResponse) {
                    this.error = 'Authentication failed';
                    return;
                }

                await this.actionGetUser();
                await this.updateAgentSetting();
                const response = await this.actionGetAccount();
                if (response.status === 200 || response.status === 201) {
                    await this.getAccountMembership();
                    if (!this.isShopifyBillingClient) {
                        this.$store.dispatch(
                            'membership/fetchStripeSubscription',
                            this.$store.getters['membership/membershipId'],
                        );
                    }
                    this.subscribeToMembershipUpdates();
                    this.$router.push({ name: 'Dashboard', query: this.queryParams });
                }
            } catch (err) {
                // this.error = err.response.data.error.message || 'An error occurred.';
                this.$emit('on-error', err);
                this.logout();
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<template>
    <md-button class="md-facebook md-block" @click="loginWithFacebook">
        <i class="fab fa-facebook-f login__facebook-button__icon"></i>
        <span> Sign in with Facebook </span>
    </md-button>
</template>

<style scoped lang="scss">
.login-social-button-facebook__icon {
    margin-right: 1rem;
}
</style>
