<template>
    <div class="fab-container">
        <!-- <button class="fab" @click="handleClick">
            <i class="material-symbols-outlined"> chat_bubble </i>
        </button> -->
        <md-button @click="openHelpCenter" class="md-round md-just-icon md-primary">
            <!-- <i class="material-symbols-outlined"> chat_bubble </i> -->
            <i class="fa-brands fa-whatsapp"></i>
        </md-button>
        <md-tooltip md-direction="left">Chat with us</md-tooltip>
    </div>
</template>

<script>
export default {
    name: 'SupportBubbleFab',
    methods: {
        openHelpCenter() {
            window.open(
                'https://wa.me/34601570569?text=Hi!%20I%20would%20like%20to%20know%20more%20about%20Lixsa',
                '_blank',
            ).focus;
        },
    },
};
</script>

<style scoped>
.fab-container {
    position: fixed;
    bottom: 2rem;
    right: 1.5rem;
    z-index: 9;
}
</style>
