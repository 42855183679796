<template>
    <div class="anb__container">
        <div>
            <md-button @click="copyAccountId" class="md-dense anb__button">
                <i class="material-symbols-outlined"> content_copy </i>
                Copy account id
            </md-button>
        </div>
        <div>
            <md-switch :class="{ 'anb__switch--disabled': !devMode }" v-model="devMode"
                ><span>Admin Mode</span></md-switch
            >
        </div>

        <md-menu :md-active.sync="menuToggled" md-size="medium" :md-offset-x="-87" :md-offset-y="10">
            <div
                class="anb__menu-trigger"
                :class="{ 'anb__menu-trigger--disabled': !devMode }"
                @click="!devMode ? null : toggleMenu()"
            >
                <div class="md-body-1">{{ currentAccountName }}</div>
                <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="15px" fill="#999999">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M7 10l5 5 5-5z" />
                </svg>
            </div>

            <md-menu-content>
                <div v-if="accounts.length === 0" class="md-caption anb__accounts-empty-state">
                    <i>No accounts available</i>
                </div>
                <div v-else-if="!loadingAccounts">
                    <md-field md-clearable class="anb__search-field">
                        <md-input v-model="searchQuery" placeholder="Search accounts"></md-input>
                    </md-field>
                    <ul class="anb__menu-items">
                        <li
                            v-for="option in filteredAccounts"
                            @click="setCurrentAccount(option.uuid)"
                            :key="option.uuid"
                            class="md-body-1"
                            :class="{ 'anb__menu-item--active': currentAccount === option.uuid }"
                        >
                            {{ option.shop_name || option.contact_name }}
                        </li>
                    </ul>
                </div>
                <md-progress-spinner
                    v-else
                    :md-diameter="20"
                    :md-stroke="3"
                    class="md-accent"
                    md-mode="indeterminate"
                ></md-progress-spinner>
            </md-menu-content>
        </md-menu>

        <div>
            <md-button :disabled="!devMode" @click="showDialog = true" class="md-icon-button md-dense anb__button">
                <i class="material-symbols-outlined"> settings </i>
                <md-tooltip md-delay="300" :md-active.sync="tooltipActive"
                    >Do not forget to add the administrator token!</md-tooltip
                >
            </md-button>
        </div>

        <md-dialog :md-active.sync="showDialog">
            <md-dialog-title>Admin Configuration</md-dialog-title>
            <md-dialog-content>
                <md-field style="width: 30vw">
                    <label for="token">Admin Token</label>
                    <md-textarea md-autogrow name="token" id="token" v-model="form.token" :disabled="sending" />
                </md-field>
            </md-dialog-content>

            <md-dialog-actions>
                <md-button @click="closeDialog">Close</md-button>
                <md-button class="md-primary" :disabled="sending || !canSave" @click="setToken">Save</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>
<script>
import adminService from '@/services/admin/adminService.js';
import Swal from 'sweetalert2';

export default {
    name: 'AdminNavBar',
    data() {
        return {
            currentAccount: '',
            accounts: [],

            form: {
                token: null,
            },
            sending: false,

            showDialog: false,

            menuToggled: false,
            tooltipActive: false,
            loadingAccounts: false,
            searchQuery: '',
        };
    },
    computed: {
        canSave() {
            return this.form.token !== null;
        },
        devMode: {
            // Getter for the computed property
            get() {
                return this.$store.state.user.devMode;
            },
            // Setter for the computed property
            set(value) {
                // Dispatch an action or commit a mutation to update the Vuex store
                this.$store.dispatch('user/setDevMode', value); // Ensure you have a 'setDevMode' mutation in your Vuex store
            },
        },
        currentAccountName() {
            return this.$store.state.user.account.shop_name || this.$store.state.user.account.contact_name;
        },
        filteredAccounts() {
            const sortAccounts = (accounts) => {
                return accounts.sort((a, b) => {
                    const nameA = (a.shop_name || a.contact_name || '').toLowerCase();
                    const nameB = (b.shop_name || b.contact_name || '').toLowerCase();
                    return nameA.localeCompare(nameB);
                });
            };

            if (!this.searchQuery) return sortAccounts(this.accounts);

            const query = this.searchQuery.toLowerCase();
            return sortAccounts(
                this.accounts.filter(
                    (account) =>
                        (account.shop_name && account.shop_name.toLowerCase().includes(query)) ||
                        (account.contact_name && account.contact_name.toLowerCase().includes(query)),
                ),
            );
        },
    },
    watch: {
        menuToggled(newValue) {
            if (newValue) {
                this.loadAccounts();
            }
        },
        devMode(newValue) {
            if (newValue === true) {
                this.tooltipActive = true;
            }
        },
    },
    methods: {
        copyAccountId() {
            navigator.clipboard.writeText(this.$store.state.user.user.account_id);
            this.showSuccessToast('Account ID copied');
        },

        toggleMenu() {
            this.menuToggled = !this.menuToggled;
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },

        setCurrentAccount(account) {
            if (this.currentAccount === account) return;

            Swal.fire({
                title: 'Change account?',
                text: `The actions you take from now on will have effect only on the account you are choosing.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4caf50',
                confirmButtonText: 'Change Account',
                buttonsStyling: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.currentAccount = account;
                    this.updateAccount();
                }
            });
        },

        async updateAccount() {
            try {
                await this.$store.dispatch('user/updateUserAccountId', this.currentAccount);
                await this.$store.dispatch('user/actionGetAccount');
                await this.$store.dispatch('agentSettings/updateAgentSetting');
                await this.$store.dispatch('membership/getAccountMembership');
                // Check if not a Shopify billing client before fetching Stripe customer
                if (!this.$store.getters['user/isShopifyBillingClient']) {
                    this.$store.dispatch(
                        'membership/fetchStripeSubscription',
                        this.$store.getters['membership/membershipId'],
                    );
                }
                this.showSuccessToast('Account successfully updated');
                setTimeout(() => {
                    location.reload();
                }, 300);
            } catch (error) {
                console.error('Error updating account: ', error);
            }
        },

        closeDialog() {
            this.showDialog = false;
        },

        clearForm() {
            this.form.token = null;
        },

        setToken() {
            if (this.form.token === null) return;
            this.sending = true;
            this.$store.dispatch('user/setIdToken', this.form.token);
            this.showSuccessToast('Admin Token Updated');
            this.sending = false;
            this.clearForm();
            this.closeDialog();
        },

        async loadAccounts() {
            this.loadingAccounts = true;
            try {
                const response = await adminService.fetchAccounts();
                this.accounts = [...response.data];
            } catch (error) {
                console.error('Error fetching accounts: ', error);
            } finally {
                this.loadingAccounts = false;
            }
        },

        toggleDevMode() {
            this.devMode = !this.devMode; // This will trigger the setter of the computed property
        },
    },

    mounted() {
        this.currentAccount = this.$store.state.user.user.account_id;
    },
};
</script>
<style lang="scss" scoped>
.anb__container {
    // border: 1px solid black;
    // border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 15px;
    background-color: whitesmoke;
    padding-right: 15px;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    z-index: 5;
}

.anb__accounts-empty-state {
    text-align: center;
}

.anb__menu-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.anb__menu-trigger--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
}

.anb__button {
    background-color: whitesmoke !important;
}

.anb__menu-items {
    list-style: none;
    padding: 0;
}

.anb__menu-items li {
    display: flex;
    text-align: start;
    align-items: center;
    padding: 5px 0;
    padding-left: 20px;
    cursor: pointer;

    svg {
        height: 15px;
        width: 15px;
        margin-right: 5px;
    }
}

.anb__menu-items li:hover {
    background-color: #f5f5f5;
}

.anb__menu-item--active {
    background-color: #f5f5f5;
}

.anb__switch--disabled {
    span {
        color: #3c4858 !important;
    }
}

.anb__search-field {
    margin: 8px 16px;
    width: calc(100% - 32px);
}
</style>
