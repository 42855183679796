<template>
    <div class="sidebar-notifications-panel sidebar-wrapper" id="sidebar-notifications-panel">
        <div class="sidebar-notifications-header">
            <div class="sidebar-notifications-header-title">
                <span class="md-body-2">Notifications</span>
            </div>
            <div class="sidebar-notifications-header-icons">
                <md-button class="md-icon-button" @click="markAllNotificationsAsRead">
                    <md-icon>mark_email_read</md-icon>
                </md-button>
                <md-button class="md-icon-button" @click="goToSettings">
                    <md-icon>settings</md-icon>
                </md-button>
            </div>
        </div>
        <md-divider></md-divider>
        <div class="sidebar-notifications-body">
            <custom-tabs :tabs="tabs" @tab-changed="tabChanged">
                <template v-slot:tab-0>
                    <!-- Content for Home tab -->
                </template>
                <template v-slot:tab-1>
                    <!-- Content for Pages tab -->
                </template>
                <template v-slot:tab-2>
                    <!-- Content for Posts tab -->
                </template>
                <template v-slot:tab-3>
                    <!-- Content for Favorites tab -->
                </template>
            </custom-tabs>

            <md-divider class="divider-gap"></md-divider>

            <div class="notifications-list">
                <div v-if="!notifications || notifications.length === 0" class="no-notifications">
                    You have no notifications
                </div>
                <div v-else>
                    <SidebarNotificationItem
                        v-for="notification in notifications"
                        :key="notification.timestamp"
                        :notification="notification"
                        @notificationRead="markNotificationAsRead(notification)"
                    />
                    <div class="see-more-notifications" v-if="totalNotifications > notifications.length">
                        <span @click="loadMoreNotifications">See More</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import notificationsService from '../../../services/notifications/notificationsService';
import { CollapseTransition } from 'vue2-transitions';
import CustomTabs from '../../Tabs/CustomTabs.vue';
import SidebarNotificationItem from './SidebarNotificationsItem.vue';

export default {
    name: 'sidebar-notifications-panel',
    emits: ['notificationsRead', 'notificationsReadAll', 'closePanel'],
    components: {
        CollapseTransition,
        CustomTabs,
        SidebarNotificationItem,
    },
    props: {
        menu: {
            type: Boolean,
            default: false,
        },
        link: {
            type: Object,
            default: () => {
                return {
                    name: '',
                    disableCollapse: Boolean,
                    children: [],
                };
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    provide() {
        return {
            addLink: this.addChild,
            removeLink: this.removeChild,
        };
    },
    inject: {
        addLink: { default: null },
        removeLink: { default: null },
        autoClose: {
            default: true,
        },
    },
    data() {
        return {
            tabs: [
                { label: 'Conversation', data: {} },
                { label: 'Task', data: {} },
                { label: 'Account', data: {} },
            ],
            selectedTab: 'conversation',
            notifications: [],
            totalNotifications: 0,
            currentNotificationsPage: 0,
        };
    },
    computed: {
        baseComponent() {
            return this.isMenu || this.link.isRoute ? 'li' : 'router-link';
        },
        linkPrefix() {
            if (this.link.name) {
                let words = this.link.name.split(' ');
                return words.map((word) => word.substring(0, 1)).join('');
            }
            return false;
        },
        isMenu() {
            return this.children.length > 0 || this.menu === true;
        },
    },
    methods: {
        goToSettings() {
            if (this.$router.currentRoute.name !== 'Notifications Settings') {
                this.$emit('closePanel');
                this.$router.push({ name: 'Notifications Settings' });
            }
        },
        tabChanged(tab) {
            this.selectedTab = tab.label.toLowerCase();
            this.currentNotificationsPage = 0;

            this.getPagedNotificationsByType(this.selectedTab.toUpperCase());
        },
        getIgnoredEvents() {
            const { notifications_config } = this.$store.getters['user/user'];

            const mainTypes = Object.keys(notifications_config.activeNotifications);
            const ignoredTypes = [];

            for (const mainType of mainTypes) {
                const subTypes = Object.keys(notifications_config.activeNotifications[mainType]);
                for (const subType of subTypes) {
                    if (!notifications_config.activeNotifications[mainType][subType]) {
                        ignoredTypes.push(subType);
                    }
                }
            }

            return ignoredTypes;
        },
        async getPagedNotificationsByType(type) {
            const ignoredTypes = this.getIgnoredEvents();
            const notifications = await notificationsService.getPagedNotificationsByType(
                type,
                ignoredTypes,
                this.currentNotificationsPage,
                6,
            );

            this.notifications = notifications.data.content;
            this.totalNotifications = notifications.data.totalElements;
        },
        async loadMoreNotifications() {
            this.currentNotificationsPage++;
            const ignoredTypes = this.getIgnoredEvents();

            const notifications = await notificationsService.getPagedNotificationsByType(
                this.selectedTab.toUpperCase(),
                ignoredTypes,
                this.currentNotificationsPage,
                6,
            );

            this.notifications = [...this.notifications, ...notifications.data.content];
        },
        async markAllNotificationsAsRead() {
            notificationsService.markAllNotificationsAsRead();

            this.notifications = this.notifications.map((notification) => {
                notification.read_by.push(this.$store.state.user.userId);

                return notification;
            });

            this.$emit('notificationsReadAll');
        },
        markNotificationAsRead(notification) {
            notification.read_by.push(this.$store.state.user.userId);

            this.emitReadNotification();
        },
        emitReadNotification() {
            this.$emit('notificationsRead');
        },
    },
    mounted() {
        if (this.addLink) {
            this.addLink(this);
        }
        if (this.link.collapsed !== undefined) {
            this.collapsed = this.link.collapsed;
        }
        if (this.isActive && this.isMenu) {
            this.collapsed = false;
        }

        this.getPagedNotificationsByType(this.selectedTab.toUpperCase());
    },
    destroyed() {
        this.$sidebar.removeSidebarLink(this);
        if (this.$el && this.$el.parentNode) {
            this.$el.parentNode.removeChild(this.$el);
        }
        if (this.removeLink) {
            this.removeLink(this);
        }
    },
};
</script>

<style lang="scss" scoped>
.sidebar-notifications-panel {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.notifications-list {
    max-height: 390px;
    overflow-y: auto;
}

.no-notifications {
    text-align: center;
    padding: 20px;
    color: #888;
}

.see-more-notifications {
    color: #4a25e1;
    border: none;
    text-align: center;
    display: block;
    margin: 5px;
}

.see-more-notifications span {
    padding: 10px;
}

.see-more-notifications span:hover {
    font-weight: bold;
    cursor: pointer;
}

.sidebar-notifications-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px 15px 20px;
    width: 100%;
}

.sidebar-notifications-header-title {
    flex-grow: 1;
}

.sidebar-notifications-header-icons {
    display: flex;
    justify-content: flex-end;
    flex-shrink: 0;
}

.md-icon-button {
    width: 30px;
    min-width: 30px;
}

.md-icon {
    justify-content: end;
}

.sidebar-notifications-body {
    margin-top: 5px;
}

.divider-gap {
    margin-top: 5px;
}
</style>
