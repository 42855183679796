import BaseDto from '../../_common/BaseDto';

class CheckoutSessionResponseDataBuilder {
    constructor() {
        this.line_items = [];
        this.metadata = {};
    }

    withId(id) {
        this.id = id;
        return this;
    }

    withMode(mode) {
        this.mode = mode;
        return this;
    }

    withStatus(status) {
        this.status = status;
        return this;
    }

    withCurrency(currency) {
        this.currency = currency;
        return this;
    }

    withAmountTotal(amount_total) {
        this.amount_total = amount_total;
        return this;
    }

    withAmountSubtotal(amount_subtotal) {
        this.amount_subtotal = amount_subtotal;
        return this;
    }

    withSuccessUrl(success_url) {
        this.success_url = success_url;
        return this;
    }

    withCancelUrl(cancel_url) {
        this.cancel_url = cancel_url;
        return this;
    }

    withPaymentStatus(payment_status) {
        this.payment_status = payment_status;
        return this;
    }

    withExpiresAt(expires_at) {
        this.expires_at = expires_at;
        return this;
    }

    withCustomerDetails(customer_details) {
        this.customer_details = customer_details;
        return this;
    }

    withLineItems(lineItems) {
        this.line_items = lineItems;
        return this;
    }

    addLineItem(lineItem) {
        this.line_items.push(lineItem);
        return this;
    }

    withMetadata(metadata) {
        this.metadata = { ...this.metadata, ...metadata };
        return this;
    }

    addMetadata(key, value) {
        this.metadata[key] = value;
        return this;
    }

    build() {
        return new CheckoutSessionResponseData(this);
    }
}

class CheckoutSessionResponseData extends BaseDto {
    constructor(builder) {
        super();
        this.id = builder.id;
        this.mode = builder.mode;
        this.status = builder.status;
        this.currency = builder.currency;
        this.amount_total = builder.amount_total;
        this.amount_subtotal = builder.amount_subtotal;
        this.success_url = builder.success_url;
        this.cancel_url = builder.cancel_url;
        this.payment_status = builder.payment_status;
        this.expires_at = builder.expires_at;
        this.customer_details = builder.customer_details;
        this.line_items = builder.line_items;
        this.metadata = builder.metadata;
    }

    static get Builder() {
        return CheckoutSessionResponseDataBuilder;
    }
}

export default CheckoutSessionResponseData;
