<template>
    <div v-if="selectedAsset.products && selectedAsset.products.length > 0" class="summary-container">
        <md-divider class="section-divider"></md-divider>
        <div class="summary-container-content">
            <span class="md-body-2 card-details-entry">Order summary</span>
            <div class="summary-product-list details-bottom">
                <table>
                    <tbody>
                        <div v-for="(product, index) in products" :key="index">
                            <tr class="summary-product">
                                <td class="product__image">
                                    <div class="summary-product-thumbnail">
                                        <div class="summary-product-thumbnail__wrapper">
                                            <img
                                                v-if="product.image"
                                                :alt="product.title"
                                                class="product-thumbnail__image"
                                                :src="product.image"
                                            />

                                            <md-progress-spinner
                                                v-else
                                                class="md-black thumbnail-image-loader product-thumbnail__image"
                                                :md-diameter="15"
                                                :md-stroke="2"
                                                md-mode="indeterminate"
                                            ></md-progress-spinner>
                                        </div>
                                        <span class="summary-product-thumbnail__quantity" aria-hidden="true">{{
                                            product.quantity
                                        }}</span>
                                    </div>
                                </td>

                                <th class="summary-product__description" scope="row">
                                    <span class="product__description__name order-summary__emphasis">{{
                                        product.title
                                    }}</span>
                                    <span class="product__description__variant order-summary__small-text">{{
                                        product.variantTitle
                                    }}</span>
                                </th>
                                <td class="product__price">
                                    <span class="order-summary__emphasis skeleton-while-loading"
                                        >{{ formatNumberWithDecimals(product.price) }}&nbsp;{{
                                            getCurrencySymbol(selectedAsset.currency || 'EUR')
                                        }}</span
                                    >
                                </td>
                            </tr>
                        </div>
                    </tbody>
                </table>
            </div>
            <div class="summary-total-lines">
                <div class="summary-row">
                    <div class="summary-subtotal">Subtotal</div>
                    <div class="summary-value">
                        {{ formatNumberWithDecimals(subtotal) }}
                        {{ getCurrencySymbol(selectedAsset.currency || 'EUR') }}
                    </div>
                </div>
                <div class="summary-row" v-if="selectedAsset.total_discount">
                    <div class="summary-subtotal">Discount</div>
                    <div class="summary-value">
                        {{ formatNumberWithDecimals(selectedAsset.total_discount) }}&nbsp;{{
                            getCurrencySymbol(selectedAsset.currency || 'EUR')
                        }}
                    </div>
                </div>

                <div class="summary-row">
                    <div class="summary-subtotal summary-bold">Total</div>
                    <div class="summary-value summary-bold">
                        {{ formatNumberWithDecimals(subtotal) }}&nbsp;{{
                            getCurrencySymbol(selectedAsset.currency || 'EUR')
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { formatNumberWithDecimals, getCurrencySymbol } from '@/utils/common.js';
import { mapActions } from 'vuex';

import conversationsService from '../../../../../../services/conversations/conversationsService.js';

export default {
    name: 'CustomerAssetsSummaryEcommerceDraftOrder',
    data() {
        return {
            URL: '',
            products: [],
        };
    },
    props: {
        selectedAsset: { type: Object },
    },
    computed: {
        // Calcular el subtotal sumando los precios de los productos
        subtotal() {
            return this.products.reduce((acc, product) => {
                return acc + product.price * product.quantity;
            }, 0);
        },
    },
    methods: {
        getCurrencySymbol,
        formatNumberWithDecimals,
        ...mapActions('user', ['apiCall']),
        async fetchProductData(product) {
            try {
                const productId = parseInt(product);
                const response = await conversationsService.getProductImage(productId);

                return response.data;
            } catch (error) {
                return null;
            }
        },
        getVariantDataByVariantId(variants, variantId) {
            return variants.find((variant) => variant.variant_id === variantId);
        },
        async loadProductsData() {
            if (this.selectedAsset?.order_data !== '{}') {
                const parsedOrderData = JSON.parse(this.selectedAsset.order_data);
                this.selectedAsset.products = parsedOrderData.draft_order.line_items;

                const imageFetchPromises = this.selectedAsset?.products.map(async (product) => {
                    const { title, variants } = await this.fetchProductData(product.product_id);
                    const {
                        title: variantTitle,
                        price,
                        images,
                    } = this.getVariantDataByVariantId(variants, product.variant_id);
                    if (images) {
                        const modifiedUrl = this.modifyImageUrlForThumbnail(images[0]);
                        return { title, quantity: product.quantity, variantTitle, image: modifiedUrl, price };
                    }
                });

                const results = await Promise.all(imageFetchPromises);
                results.forEach((result) => {
                    if (result) {
                        this.products.push(result);
                    }
                });
            } else {
                this.selectedAsset.products = [];
            }
        },
        modifyImageUrlForThumbnail(url) {
            if (url.includes('?')) {
                return `${url}&width=100`;
            } else {
                return `${url}?width=100`;
            }
        },
    },
    async created() {
        this.URL = process.env.VUE_APP_API_URL;
        await this.loadProductsData();
    },
};
</script>
