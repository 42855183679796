import axios from 'axios';
import store from '@/store';

const baseURL = `${process.env.VUE_APP_API_URL}`;
const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}) => {
    try {
        return store.dispatch('user/apiCall', async (token) => {
            const config = {
                method: method,
                url: `${baseURL}/accounts/${store.state.user.user.account_id}/${url}`,
                headers: getAuthHeader(token),
                params: { ...params },
                data: data,
            };
            return await axios(config);
        });
    } catch (error) {
        throw error;
    }
};

/**
 * @typedef {Object} ShopifySubscriptionData
 * @property {('FREE'|'STARTUP'|'SCALING'|'PRO')} membership_subscription_plan - The subscription plan type
 * @property {('MONTH'|'YEAR'|'UNDEFINED')} membership_duration_type - The duration type of the membership
 * @property {string} return_url - The URL to return to after subscription creation
 */

const shopifyBillingService = {
    /**
     * Creates a Shopify subscription for a given membership
     * @param {ShopifySubscriptionData} subscriptionData - The subscription data
     * @returns {Promise<Object>} The response from the API
     */
    createShopifySubscription: async (subscriptionData) => {
        const url = `membership/${store.state.user.user.account_id}/subscription`;
        return apiRequest('POST', url, subscriptionData);
    },

    /**
     * Cancels a Shopify subscription for a given membership
     * @param {string} membershipId - The ID of the membership
     * @returns {Promise<Object>} The response from the API
     */
    cancelShopifySubscription: async (membershipId) => {
        const url = `membership/${membershipId}/cancel`;
        return apiRequest('POST', url);
    },

    /**
     * Creates a Shopify pack checkout link for a given membership
     * @param {Object} packData - The pack data
     * @returns {Promise<Object>} The response from the API
     */
    createShopifyPackCheckoutLink: async (packData) => {
        const url = `membership/${store.state.user.user.account_id}/pack`;
        return apiRequest('POST', url, packData);
    },
};

export default shopifyBillingService;
