<script>
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'RegisterSocialButtonGoogle',
    emits: ['on-error'],
    props: ['queryParams'],
    computed: {
        ...mapGetters('user', ['isShopifyBillingClient']),
    },
    methods: {
        ...mapActions('user', [
            'actionGetUser',
            'actionCreateAccount',
            'refreshToken',
            'actionGetAccount',
            'actionSignUpWithPopup',
        ]),

        ...mapActions('membership', ['getAccountMembership', 'subscribeToMembershipUpdates']),

        ...mapActions('agentSettings', ['updateAgentSetting']),

        logout() {
            this.$store
                .dispatch('user/logout')
                .then(() => {
                    if (this.$route.path !== '/') {
                        this.$router.push({ name: 'Login', query: { logout: 'true' } });
                    }
                })
                .catch((error) => {
                    this.error = error.message;
                });
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },

        async registerWithGoogle() {
            const myUuid = uuidv4();
            this.error = '';
            this.loading = true;

            try {
                const signupResponse = await this.actionSignUpWithPopup();

                if (!signupResponse) {
                    this.error = 'Authentication failed';
                    return;
                }

                let sourcePlatform = 'STRIPE';
                if (this.queryParams.source) {
                    sourcePlatform =
                        this.queryParams.source.toLowerCase() === 'shopify'
                            ? 'SHOPIFY_BILLING'
                            : this.queryParams.source;
                }

                const accData = {
                    uuid: myUuid,
                    email: signupResponse._tokenResponse.email,
                    user_id: signupResponse._tokenResponse.localId,
                    contact_name: signupResponse._tokenResponse.displayName,
                    source_platform: sourcePlatform,
                    referredBy: null,
                };

                // Check if there's a referral from Tolt and add it to the account data
                if (window.tolt_referral) {
                    accData.referredBy = window.tolt_referral;
                }

                try {
                    await this.actionCreateAccount(accData);
                } catch (createAccountError) {
                    if (createAccountError.response && createAccountError.response.status === 400) {
                        this.showErrorToast('Account already exists');
                        throw createAccountError;
                    } else if (createAccountError.response && createAccountError.response.status === 409) {
                        this.showErrorToast('Shop already registered');
                        throw createAccountError;
                    } else {
                        throw createAccountError;
                    }
                }

                await this.refreshToken();
                await this.actionGetUser();
                await this.updateAgentSetting();
                const response = await this.actionGetAccount();
                if (response.status === 200 || response.status === 201) {
                    await this.getAccountMembership();
                    if (!this.isShopifyBillingClient) {
                        this.$store.dispatch(
                            'membership/fetchStripeSubscription',
                            this.$store.getters['membership/membershipId'],
                        );
                    }
                    this.subscribeToMembershipUpdates();
                    if (window.tolt) window.tolt.signup(accData.email);
                    await this.$router.push({ name: 'Dashboard', query: this.queryParams });

                    // this.$router.push({ name: "Onboard" });
                    this.showSuccessToast('Sign up complete!');
                }
            } catch (err) {
                // Handle other errors
                console.error('An error occurred during registration:', err);
                this.$emit('on-error', err);
                this.logout();
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<template>
    <md-button class="md-outline md-gray register-social-button-google" v-on:click="registerWithGoogle">
        <div class="register-social-button-google__icon"></div>
        <span>Google</span>
    </md-button>
</template>

<style scoped lang="scss">
.register-social-button-google {
    width: 100%;

    &__icon {
        height: 17px;
        width: 17px;
        background-image: url('@/assets/icons/google-logo.svg');
        margin-right: 0.7rem;
    }
}
</style>
