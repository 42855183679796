import BaseDto from '../../_common/BaseDto';

class CustomerDetailsDtoBuilder {
    withEmail(email) {
        this.email = email;
        return this;
    }

    withName(name) {
        this.name = name;
        return this;
    }

    withPhone(phone) {
        this.phone = phone;
        return this;
    }

    withAddress(address) {
        this.address = address;
        return this;
    }

    build() {
        return new CustomerDetailsDto(this);
    }
}

class CustomerDetailsDto extends BaseDto {
    constructor(builder) {
        super();
        this.email = builder.email;
        this.name = builder.name;
        this.phone = builder.phone;
        this.address = builder.address;
    }

    static get Builder() {
        return CustomerDetailsDtoBuilder;
    }
}

export default CustomerDetailsDto;
